<template>
    <b-modal
        id="1"
        :title="title"
        v-model="isOpenLocal"
        size="lg"
        scrollable
        :hide-footer="hideFooter"
        :ok-title="capitalize($t('validate'))"
        :cancel-title="capitalize($t('cancel'))"
        @hide="tryCloseUserModal"
        @hidden="handleCancel"
        @ok="handleOk"
    >
        <validation-observer
            ref="observerRef"
            #default="{handleSubmit, errors}">
            <b-form @submit.prevent="handleSubmit(submitValidatedUserLocal)">
                <form-individual
                    :individual="userLocal"
                    :formErrors="errors"
                />
                <button ref="submitUserFormRef"
                        class="d-none"></button>
            </b-form>
        </validation-observer>
    </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver } from 'vee-validate'
import { capitalize } from '../../utils/filter'

import useAPI from '../../utils/useAPI'
import FormIndividual from '../form/individual/Individual.vue'

export default {
    components: {
        ValidationObserver,
        FormIndividual
    },
    props: {
        user: {
            type: Object,
            default: () => {}
        },
        title: {
            type: String,
            default: ''
        },
        isOpen: {
            type: Boolean,
            default: false
        }
    },
    setup (props, { emit }) {
        // ------------------------------------------------
        // Data
        // ------------------------------------------------
        const isOpenLocal = ref(false)
        const userLocal = ref(props.user)
        const submitUserFormRef = ref(null)
        const observerRef = ref(null)

        // ------------------------------------------------
        // Computed
        // ------------------------------------------------
        const { currentBreakPoint } = useAPI()

        const hideFooter = computed(() => {
            return !['xs', 'sm'].includes(currentBreakPoint.value)
        })

        // ------------------------------------------------
        // Watch
        // ------------------------------------------------
        watch(isOpenLocal, (val) => {
            emit('update:isOpen', val)
        })
        watch(userLocal, (val) => {
            emit('update:user', val)
        })

        // ------------------------------------------------
        // Methods
        // ------------------------------------------------
        const tryCloseUserModal = (modalEvent) => {
            if (modalEvent.trigger == 'backdrop') {
                // Click outside
                modalEvent.preventDefault()
                submitUserFormRef.value.click()
            }
        }

        const submitValidatedUserLocal = () => {
            emit('submitValidated')
        }

        const handleOk = async (bvModalEvent) => {
            bvModalEvent.preventDefault()

            const isValid = await observerRef.value.validate()
            if (isValid) {
                submitValidatedUserLocal()
            }
        }

        const handleCancel = () => {}

        // ------------------------------------------------
        // Mounted
        // ------------------------------------------------

        // ------------------------------------------------
        // Setup
        // ------------------------------------------------
        return {
            // Components
            capitalize,

            // Data
            isOpenLocal,
            userLocal,
            submitUserFormRef,
            observerRef,

            // Computed
            hideFooter,

            // Methods
            tryCloseUserModal,
            submitValidatedUserLocal,
            handleOk,
            handleCancel,
        }
    },
    data () {
        return {}
    },
    computed: {},
    watch: {
        user: function (val) {
            this.userLocal = val
        },
        isOpen: function (val) {
            this.isOpenLocal = val
        }
    },
    methods: {},
    mounted () {
    },
    created () {
    }
}
</script>

<style lang="scss">

</style>