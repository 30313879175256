<template>
  <b-row>
    <b-col md="12">
      <select2
          :model.sync="taskEvent.workFlo"
          name="opportunity"
          :placeholder="$t('Choose anE ') + $t('opportunity')"
          :options="autocompleteWorkFlos"
          :disabled="isDisabled"
      />
    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

import Select2 from '../../../input/Select2.vue'
import useAPI from '../../../../utils/useAPI'

export default {
  components: {
    Select2
  },
  props: {
    taskEvent: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteWorkFlos } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed
      autocompleteWorkFlos,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>