<template>
  <b-row>
    <b-col
        cols="12"
    >
      <div
          @mouseover="stepHover = true"
          @mouseleave="stepHover = false"
          class="d-flex justify-content-between border-bottom-primary mb-2 pb-25"
          :class="stepHover?'border-bottom-primary':'border-bottom'"
      >

        <div
            @mouseover="radioHover = true"
            @mouseleave="radioHover = false"
            @click="isDisabled?false:toggleStepStatus"
            class="cursor-pointer text-primary my-auto pr-1"
        >
          <icon
              v-if="isNew"
              icon="plus"
              size="lg"
              :disabled="isDisabled"
          />

          <icon v-else-if="step.isDone"
                size="lg"
                :disabled="isDisabled"
                icon="check-circle"/>
          <icon v-else-if="radioHover"
                size="lg"
                :disabled="isDisabled"
                icon="check-circle"/>
          <icon v-else
                size="lg"
                library="far"
                :disabled="isDisabled"
                icon="circle"/>
        </div>

        <div class="w-100">
          <input v-model="step.title"
                 class="invisibleInput"
                 :class="{'text-decoration-line-through': step.isDone}"
                 :placeholder="isNew?$t('AddStep'):''"
                 :disabled="isDisabled"
                 @keyup.enter="$emit('submitStep')">

        </div>
      </div>

    </b-col>
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'

export default {
  components: {},
  props: {
    step: {
      type: Object,
      default: () => {}
    },
    index: {
      type: Number,
      required: true
    },
    isNew: {
      type: Boolean,
      default: false
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const stepHover = ref(false)
    const radioHover = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const toggleStepStatus = () => {
      if (props.step.isDone == true) {
        props.step.isDone = false
      } else {
        props.step.isDone = true
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      stepHover,
      radioHover,

      // Computed

      // Methods
      toggleStepStatus,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>