import store from '@/store'

export const useUsers = () => {
  // ======================================================================
  // ==================              METHODS             ==================
  // ======================================================================
  const submitValidatedUser = (user) => {
    return new Promise((resolve, reject) => {
      if ('id' in user) {
        updateUser(user)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewUser(user)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewUser = (user) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user2/addUser', user)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateUser = (user) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user2/updateUser', user)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeUser = (user) => {
    return new Promise((resolve, reject) => {
      store.dispatch('user2/removeUser', user)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // ======================================================================
  // ==================               SETUP              ==================
  // ======================================================================
  return {
    submitValidatedUser,
    removeUser
  }
}