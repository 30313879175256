import { ref, computed, watch, onMounted, reactive } from '@vue/composition-api'
import { getCurrentInstance } from '@vue/composition-api'
import { dom } from '@fortawesome/fontawesome-svg-core'
import { getRoute, isProdEnv } from '../../utils/utils'
import { capitalize } from '../../utils/filter'

import dayGridPlugin from '@fullcalendar/daygrid'
import timeGridPlugin from '@fullcalendar/timegrid'
import listPlugin from '@fullcalendar/list'
import interactionPlugin from '@fullcalendar/interaction'
import i18n from '@/libs/i18n'
import store from '@/store'
import moment from 'moment'
import { eventToCalendarEvent, getEventIcons } from '../../utils/utils'

export const useCalendar = () => {
  dom.watch()

  const fetchEvents = (fetchInfo, successCallback) => {
    if (!fetchInfo) return
    store.getters['calendar/getEvents'].then(result => {
      successCallback(result)
    })
  }

  const currentBreakPoint = computed(() => {
    return store.getters['app/currentBreakPoint']
  })

  // ------------------------------------------------
  // Data
  // ------------------------------------------------
  let calendarApi = null
  const refCalendar = ref(null)
  const viewDates = ref(null)
  const { emit, refs } = getCurrentInstance()

  const blankEvent = {
    title: '',
    start: null,
    end: null,
    allDay: false,
    url: '',
    extendedProps: {
      color: 'primary',
      icon: 'plane',
      entity: null
    },
  }
  const event = ref(null)

  const calendarOptions = ref({
    plugins: [dayGridPlugin, interactionPlugin, timeGridPlugin, listPlugin],
    initialView: ['xs', 'sm'].includes(currentBreakPoint.value) ? 'timeGridDay' : 'timeGridWeek',
    headerToolbar: {
      start: 'sidebarToggle, prev, today, next, title',
      end: 'dayGridMonth,timeGridWeek,timeGridDay,listMonth',
    },
    buttonText: {
      today: i18n.t('today'),
      month: i18n.t('month'),
      week: i18n.t('week'),
      day: i18n.tc('day'),
      list: i18n.t('list')
    },
    allDayText: i18n.t('allDay'),
    locale: 'fr',
    firstDay: 1,
    // timeZone: 'UTC',
    nowIndicator: true,
    selectable: true,
    editable: true,
    eventResizableFromStart: true,
    dragScroll: true,
    dayMaxEvents: 2,
    navLinks: true,
    events: fetchEvents,

    datesSet (dateInfo) {
      viewDates.value = {
        view: dateInfo.view.type,
        start: dateInfo.start,
        end: dateInfo.end
      }
    },

    moreLinkContent (arg) {
      return { html: arg.shortText + ' ' + i18n.tc('more', arg.num) }
    },

    // eventRender: function (info) {
    //   var tooltip = new Tooltip(info.el, {
    //     title: info.event.extendedProps.description,
    //     placement: 'top',
    //     trigger: 'hover',
    //     container: 'body'
    //   })
    // },
    eventClassNames ({ event: calendarEvent }) {
      // eslint-disable-next-line no-underscore-dangle
      // const colorName = calendars.value.find(cc => cc.name == calendarEvent._def.extendedProps.calendar).color
      // const colorName = calendarsColors.value.find(cc => cc.label == calendarEvent._def.extendedProps.calendar).color
      const colorName = calendarEvent._def.extendedProps.color

      // return 'bg-light-' + calendarEvent._def.extendedProps.color
      return `bg-light-${colorName}`
    },
    eventContent (arg) {
      return { domNodes: createContextMenu(arg.event, arg.timeText) }
    },
    select (selectionInfo) {
      // console.log(selectionInfo)
      let start = moment(selectionInfo.start).format('YYYY-MM-DD HH:mm')
      let end = moment(selectionInfo.end).format('YYYY-MM-DD HH:mm')
      let minutes = (end - start) / 60000

      if (minutes != 30 && selectionInfo.allDay == false) {
        event.value = JSON.parse(JSON.stringify(Object.assign(event.value, {
          allDay: selectionInfo.allDay,
        })))
        event.value.start = start
        event.value.end = end

        // console.log("dateClick", event.value)
        emit('dateClick', event.value)
      }

    },
    eventClick ({ event: clickedEvent }) {
      hideContextsMenus()

      // console.log(clickedEvent)
      // console.log(grabEventDataFromEventApi(clickedEvent))

      emit('eventClick', grabEventDataFromEventApi(clickedEvent))
    },
    eventDidMount: (arg) => {
      // console.log(arg)
      const eventId = arg.event.id
      arg.el.addEventListener('contextmenu', (jsEvent) => {
        jsEvent.preventDefault()

        let calendarContextMenus = document.getElementsByClassName('calendarContextMenu')
        for (let i = 0; i < calendarContextMenus.length; i++) {
          if (calendarContextMenus[i].getAttribute('id') == ('contextMenu' + eventId)) {
            let contextMenu = document.getElementById('contextMenu' + eventId)
            contextMenu.classList.remove('d-none')

            let container = contextMenu.parentElement.parentElement.parentElement.parentElement
            container.style.zIndex = '2'
          } else {
            let contextMenu = document.getElementById(calendarContextMenus[i].getAttribute('id'))
            contextMenu.classList.add('d-none')

            let container = contextMenu.parentElement.parentElement.parentElement.parentElement
            container.style.zIndex = '1'
          }
        }

      })
    },
    dateClick (info) {
      hideContextsMenus()
      resetEvent()

      // console.log(info)
      let start = moment(info.date).format('YYYY-MM-DD HH:mm')

      event.value = JSON.parse(JSON.stringify(Object.assign(event.value, {
        allDay: info.allDay,
      })))
      event.value.start = start

      if (info.allDay == false) {
        let end = moment(info.date).format('YYYY-MM-DD HH:mm')
        event.value.end = moment(end).add(30, 'minutes').format('YYYY-MM-DD HH:mm')
      }
      // console.log("dateClick", event.value)

      emit('dateClick', event.value)
    },
    eventDrop ({ event: droppedEvent }) {
      console.log("eventDrop", droppedEvent)
      updateEvent(grabEventDataFromEventApi(droppedEvent))
    },
    eventResize ({ event: resizedEvent }) {
      // console.log("eventResize")
      updateEvent(grabEventDataFromEventApi(resizedEvent))
    },
  })

  const isEventHandlerSidebarActive = ref(false)

  const isCalendarOverlaySidebarActive = ref(false)

  // ------------------------------------------------
  // Computed
  // ------------------------------------------------
  const calendars = computed(() => {
    return store.getters['calendar/getCalendars']
  })

  const selectedCalendars = computed({
    get: () => store.getters['calendar/getSelectedCalendars'],
    set: val => {
      store.commit('calendar/SET_SELECTED_CALENDARS', val)
    }
  })

  // ------------------------------------------------
  // Watch
  // ------------------------------------------------
  watch(refCalendar, (val) => {
    if (val != null) {
      calendarApi = refCalendar.value.getApi()
    }
  })

  watch(selectedCalendars, (val) => {
    refetchEvents()
  })

  // ------------------------------------------------
  // Methods
  // ------------------------------------------------

  const grabEventDataFromEventApi = eventApi => {
    // console.log(eventApi)

    let {
      id,
      title,
      start,
      end,
      // eslint-disable-next-line object-curly-newline
      extendedProps: { color, entity },
      allDay,
    } = eventApi

    if (end == null) {
      let diff = moment(moment(eventApi._def.extendedProps.entity.endDate)).diff(start, 'minutes')
      end = moment(start).add(diff, 'minutes')
    }

    start = moment(start).format('YYYY-MM-DD HH:mm')
    end = moment(end).format('YYYY-MM-DD HH:mm')

    // console.log(start, end)

    return {
      id,
      title,
      start,
      end,
      extendedProps: {
        color,
        entity,
      },
      allDay,
    }
  }

  const addEvent = eventData => {
    // eventsLocal.value.push(eventData)
  }

  const updateEvent = eventData => {
    // console.log(eventData)
    if (getRoute().name == 'Workflow view') {
      eventData.extendedProps.entity.workflow = parseInt(getRoute().params.workflowId)
    }

    emit('updateEventDates', eventData.extendedProps.entity, eventData.start, eventData.end)
  }

  const resetEvent = () => {
    event.value = JSON.parse(JSON.stringify(blankEvent))
  }

  const refetchEvents = () => {
    if (calendarApi != null) {
      // console.log("refetch")
      calendarApi.refetchEvents()
    }
  }

  const refetchEventAfterUpdate = (updatedEvent) => {
    if (calendarApi != null) {
      // console.log("refetch update")
      // console.log(updatedEvent)

      const existingEvent = calendarApi.getEventById(updatedEvent.id)

      existingEvent.setProp('title', updatedEvent.title != null ? updatedEvent.title : (updatedEvent.type == 'holiday' ? i18n.t('Holidays') : ''))
      existingEvent.setProp('editable', (!updatedEvent.isValidated && updatedEvent.type != 'holiday'))
      existingEvent.setDates(updatedEvent.startDate, updatedEvent.endDate, { allDay: updatedEvent.allDay })

      existingEvent.setExtendedProp('entity', updatedEvent)
      existingEvent.setExtendedProp('icons', getEventIcons(updatedEvent))
      // existingEvent.setExtendedProp('secondaryIcon', (updatedEvent.type == 'holiday' && updatedEvent.isValidated == null) ? 'stopwatch' : null)

    }
  }

  const removeCalendarEvent = (eventId) => {
    if (calendarApi != null) {
      calendarApi.getEventById(eventId).remove()
    }
  }

  const getViewType = () => {
    if (calendarApi.view.type == 'dayGridMonth') {
      return 'month'
    } else if (calendarApi.view.type == 'timeGridWeek') {
      return 'week'
    } else if (calendarApi.view.type == 'dayGridWeek') {
      return 'day'
    } else if (calendarApi.view.type == 'listWeek') {
      return 'list'
    } else {
      return null
    }
  }

  const hideContextsMenus = () => {
    let calendarContextMenus = document.getElementsByClassName('calendarContextMenu')
    for (let i = 0; i < calendarContextMenus.length; i++) {
      calendarContextMenus[i].classList.add('d-none')
    }
  }

  const createContextMenu = (argEvent, timeText) => {
    hideContextsMenus()
    // console.log(argEvent)
    let mainFrame = document.createElement('div')

    let detail = document.createElement('div')
    detail.classList.add('fc-event-main-frame')
    detail.classList.add('text-truncate')

    let eventTime = document.createElement('div')
    eventTime.classList.add('fc-event-time')
    eventTime.innerHTML = timeText
    detail.appendChild(eventTime)

    let eventTitleContainer = document.createElement('div')
    eventTitleContainer.classList.add('fc-event-title-container')
    let eventTitleSticky = document.createElement('div')
    eventTitleSticky.classList.add('fc-event-title')
    eventTitleSticky.classList.add('fc-sticky')

    if (
      'icons' in argEvent.extendedProps &&
      argEvent.extendedProps.icons.length != 0
    ) {
      argEvent.extendedProps.icons.forEach(icon => {
        let eventIcon = document.createElement('i')
        eventIcon.classList.add('fa')
        eventIcon.classList.add('fa-' + icon)
        eventIcon.classList.add('mr-25')
        if (['exclamation-triangle', 'stopwatch'].includes(icon)) {
          eventIcon.classList.add('text-warning')
        }

        eventTitleSticky.appendChild(eventIcon)
      })

      let eventTitle = document.createElement('span')
      eventTitle.classList.add('ml-25')
      eventTitle.innerHTML = argEvent.title
      eventTitleSticky.appendChild(eventTitle)

      eventTitleContainer.appendChild(eventTitleSticky)
      detail.appendChild(eventTitleContainer)
    } else {
      eventTitleSticky.innerHTML = argEvent.title
      eventTitleContainer.appendChild(eventTitleSticky)
      detail.appendChild(eventTitleContainer)
    }
    mainFrame.appendChild(detail)

    let menu = document.createElement('ul')
    menu.setAttribute('id', ('contextMenu' + argEvent.id))
    menu.setAttribute('tabindex', '-1')
    menu.setAttribute('role', 'menu')
    menu.setAttribute('aria-hidden', 'true')
    menu.classList.add('v-context')
    // menu.classList.add('position-relative')
    menu.classList.add('position-absolute')
    menu.classList.add('d-none')
    menu.classList.add('calendarContextMenu')
    menu.style.zIndex = '30'
    // menu.style.position = 'absolute!important'
    menu.style.top = '0'
    menu.style.right = '100%'

    let lis = ['edit', 'duplicate', 'addTask', 'delete']

    lis.forEach(text => {
      let li = document.createElement('li')
      li.style.zIndex = '31'
      let a = document.createElement('a')
      a.setAttribute('href', '#')
      a.setAttribute('target', '_self')
      a.setAttribute('eventId', argEvent.id)
      a.classList.add('d-flex')
      a.classList.add('align-items-center')
      if (
        // text == 'delete' &&
        argEvent._def.extendedProps.color == 'secondary'
      ) {
        a.classList.add('disabled')
      }
      a.addEventListener('click', (e) => {
        if (argEvent._def.extendedProps.color != 'secondary') {
          if (text != 'edit') {
            e.preventDefault()
            e.stopPropagation()
          }
          if (text == 'duplicate') {
            let event = store.getters['event/getEvent'](argEvent.id)
            emit('duplicateEvent', event)
          } else if (text == 'addTask') {
            emit('addTask', argEvent._def)
          } else if (text == 'delete') {
            emit('deleteEvent', argEvent)
          }
        } else {
          e.preventDefault()
          e.stopPropagation()
        }
        menu.classList.add('d-none')
      })
      let span = document.createElement('span')
      span.classList.add('ml-75')
      span.innerHTML = capitalize(i18n.t(text))
      a.appendChild(span)
      li.appendChild(a)
      menu.appendChild(li)
    })
    mainFrame.appendChild(menu)

    return [mainFrame]
  }

  // ------------------------------------------------
  // Mounted
  // ------------------------------------------------
  // if (isProdEnv()) {
  //   // calendarOptions.value.timeZone = 'UTC'
  // }

  fetchEvents()
  resetEvent()
  return {
    // Data
    refCalendar,
    viewDates,
    event,
    calendarOptions,
    isEventHandlerSidebarActive,
    isCalendarOverlaySidebarActive,

    // Computed
    calendars,
    selectedCalendars,

    // Methods
    addEvent,
    updateEvent,
    refetchEvents,
    refetchEventAfterUpdate,
    removeCalendarEvent
  }
}