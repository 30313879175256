<template>
  <div>

    <b-tabs>
      <b-tab>
        <template #title>
          <icon
              icon="sticky-note"
              class="mr-50"
          />
          <span>{{ capitalize($t('notes')) }}</span>
        </template>


        <step-list
            :steps="itemLocal.steps"
            :is-disabled="isDisabled"
        />

        <textarea-field
            :model.sync="itemLocal.notes"
            name="notes"
            :displayLabel="false"
            class="mt-2"
            :disabled="isDisabled"
        />

<!--        <input-textarea-->
<!--            :text.sync="itemLocal.notes"-->
<!--            class="mt-2"-->
<!--            :disabled="isDisabled"-->
<!--        />-->
      </b-tab>

      <b-tab>
        <template #title>
          <icon
              icon="file-alt"
              class="mr-50"
          />
          <span>{{ capitalize($t('content')) }}</span>
        </template>

        <b-card-text>
          <email-view
              v-if="itemLocal.writtenEmail != null"
              :email="itemLocal.writtenEmail"
              :read-only="true"
              :allow-collapse="true"
              class="mt-2"
          />

          <div v-else-if="'documents' in itemLocal && itemLocal.documents.length">
            <b-row>
              <b-col
                  md="3"
                  v-for="(document, i) in itemLocal.documents"
              >
                <document-card
                    v-if="isAllDocumentsDisplayed!=true?(i < 4):true"
                    :document="document"
                    :is-deletable="false"
                    :small="true"

                    @click="downloadByDocument(document)"
                    @download="downloadByDocument(document)"
                />
              </b-col>
            </b-row>

            <div class="d-flex">
              <div
                  v-if="itemLocal.documents.length >= 4 && isAllDocumentsDisplayed == false"
                  class="cursor-pointer"

                  @click="isAllDocumentsDisplayed = true"
              >
                <icon
                    icon="angle-double-down"
                    class="mr-50"
                />
                <span>
                  {{ capitalize($t('displayNumberAttachments', { number: itemLocal.documents.length })) }}
                </span>
              </div>

              <!--              <div-->
              <!--                  v-if="itemLocal.documents.length >= 2"-->
              <!--                  class="ml-1 cursor-pointer"-->

              <!--                  @click="downloadAllAttachmentsLocal"-->
              <!--              >-->
              <!--                <icon-->
              <!--                    icon="arrow-down"-->
              <!--                    class="mr-50"-->
              <!--                />-->
              <!--                <span>-->
              <!--                  {{ capitalize($t('downloadAll')) }}-->
              <!--                </span>-->
              <!--              </div>-->
            </div>
          </div>

          <span v-else>
            -
          </span>
        </b-card-text>
      </b-tab>

    </b-tabs>

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../../utils/filter'
import TextareaField from '../../../input/Textarea.vue'
import StepList from '../../step/Step.vue'
import { clone, downloadByDocument } from '../../../../utils/utils'
import EmailView from '../../../email/v2/components/EmailView.vue'
import DocumentCard from '../../../card/Document.vue'
import InputTextarea from '../../../input/InputTextarea.vue'

export default {
  components: {
    InputTextarea,
    DocumentCard,
    EmailView,
    TextareaField,
    StepList
  },
  props: {
    item: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.event))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isAllDocumentsDisplayed = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const itemLocal = computed({
      get () {
        return props.item
      },
      set (val) {
        emit('update:item', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isAllDocumentsDisplayed,

      // Computed
      itemLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { downloadByDocument, capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>