<template>
  <b-row>

    <b-col md="12">
      <field-work-flows
          :entity.sync="taskEvent"
      />
    </b-col>


    <!--    <b-col :md="taskEvent.workflow==null?12:6">-->
    <!--      <field-select-->
    <!--        :model.sync="taskEvent.workflow"-->
    <!--        name="workflow"-->
    <!--        :options="autocompleteWorkflows"-->
    <!--        :disabled="$can('manage', 'all')?false:isDisabled"-->

    <!--        @input="resetTaskEventPhase"-->
    <!--        />-->
    <!--    </b-col>-->

    <!--    <b-col md="6"-->
    <!--           v-if="taskEvent.workflow">-->
    <!--      <field-select-->
    <!--          :model.sync="taskEvent.phase"-->
    <!--          :name="'phase'"-->
    <!--          :placeholder="$t('Choose a phase')"-->
    <!--          :options="availablePhases"-->
    <!--          :label="'name'"-->
    <!--          :disabled="$can('manage', 'all')?false:isDisabled"-->
    <!--          class="w-100"-->
    <!--          :key="componentKey"-->
    <!--      />-->
    <!--    </b-col>-->
  </b-row>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { isObject } from '../../../../utils/utils'

import FieldSelect from '../../../input/Select2.vue'
import FieldWorkFlows from '../../../input/Workflow.vue'
import useAPI from '../../../../utils/useAPI'
import store from '../../../../store'

export default {
  components: {
    FieldSelect,
    FieldWorkFlows
  },
  props: {
    taskEvent: {
      type: Object,
      default: () => {}
    },
    isDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const componentKey = ref(0)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { autocompleteWorkflows, workflows } = useAPI()

    const availablePhases = computed(() => {
      // console.log(props.taskEvent.workflow)

      if (props.taskEvent.workflow) {
        if (!('phases' in props.taskEvent.workflow)) {
          store.dispatch('workflow/getWorkflow', props.taskEvent.workflow.id)
              .then(workflow => {
                props.taskEvent.workflow = workflow
                // console.log(workflow.phases)
                return workflow.phases
              })
        } else {
          return props.taskEvent.workflow.phases
        }
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    // watch(availablePhases, val => {
    //   console.log(val)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const resetTaskEventPhase = () => {
      props.taskEvent.phase = null
      componentKey.value++
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      componentKey,

      // Computed
      autocompleteWorkflows,
      // workflows,
      availablePhases,

      // Methods
      resetTaskEventPhase,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>