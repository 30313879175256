<template>
  <div class="w-100">

    <!--    <v-select-->
    <!--        v-model="localModel"-->
    <!--        :options="listContacts"-->
    <!--        label="_display"-->
    <!--        :getOptionKey="getOptionKey"-->
    <!--        :multiple="multiple"-->
    <!--        @input="newSelectedContact"-->
    <!--    />-->
    <div class="d-flex justify-content-between">
      <field-select
          :name="name"
          :model.sync="localModel"
          :options="options"
          :label="(displayCompanies?'_displayWithCompany':'_display')"
          :isRequired="isRequired"
          :allowAdd="allowAdd && !displayOnly"
          addText="AddUser"
          :listIndex="listIndex"
          :display-label="displayLabel"
          @addCallback="selectContact(true)"
          @input="newSelectedContact"
          class="w-100"
          :placeholder="placeholder?placeholder:name"
          :disabled="isDisabled"
          :reduce="reduce"
          :multiple="multiple"
      />

      <div
          class="ml-1 d-flex"
          v-if="!displayOnly && (Array.isArray(localModel) && localModel.length == 1)"
      >
        <button-external-link
            :withBorder="false"
            @click="selectContact(false)"
            :disabled="localModel == null"
            class="my-auto"
        />
      </div>

    </div>


    <!-- modal ticket -->
    <modal-user
        :user.sync="currentContact"
        :title="isContactEdit?$t('EditUser'):$t('NewUser')"
        :isOpen.sync="contactModalShow"
        :is-contact-edit="isContactEdit"
        @submitValidatedContact="submitValidatedContactLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import modalUser                from '../prompt/User.vue'
import FieldSelect              from './Select2.vue'
import ButtonExternalLink       from '../button/ExternalLink.vue'
import { useUsers }             from '../../views/contacts2/contactUsers/useUsers'
import { clone, getUserData }   from '../../utils/utils'
import store                    from '../../store'

export default {
  components: { ButtonExternalLink, FieldSelect, modalUser },
  props: {
    name: {
      type: String,
      default: 'contact'
    },
    user: {},
    isRequired: {
      type: Boolean,
      default: false
    },
    listIndex: {
      type: Number,
      default: -1
    },
    withMe: {
      type: Boolean,
      default: true
    },
    multiple: {
      type: Boolean,
      default: false
    },
    displayOnly: {
      type: Boolean,
      default: false
    },
    displayLabel: {
      type: Boolean,
      default: true
    },
    placeholder: {
      type: String,
      default: ''
    },
    isDisabled: {
      type: Boolean,
      default: false
    },
    allowAdd: {
      type: Boolean,
      default: true
    },
    options: {
      type: Array,
      default: () => []
    },
    reduce: {
      type: String,
      default: null
    },
    displayCompanies: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(props.options)
    // console.log(props.user)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const localModel = ref(null)
    const currentContact = ref({})
    const isContactEdit = ref(false)
    const contactModalShow = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(props.user, val => {
      console.log(val)
      localModel.value = clone(props.user)
    }, { deep: true })

    // watch(localModel, val => {
    //   console.log(val)
    // })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { submitValidatedUser } = useUsers()

    const resetContact = () => {
      currentContact.value = clone(store.getters['user2/getEmptyUser'])
      isContactEdit.value = false
    }

    const newSelectedContact = (selectedContact) => {
      // console.log(selectedContact)
      emit('update:user', selectedContact)
      emit('input', selectedContact)
      emit('change')
    }

    const removeMe = (listContacts) => {
      return listContacts.filter(c => c.id != getUserData().id)
    }

    const selectContact = (isNew = false) => {
      if (isNew) {
        resetContact()
        contactModalShow.value = true

      } else {
        if (localModel.value != null) {
          isContactEdit.value = true

          store.dispatch('user2/getUser', Array.isArray(localModel.value)?localModel.value[0]:localModel.value)
              .then(result => {
                currentContact.value = result.data
                contactModalShow.value = true
              })

        } else {
          resetContact()
        }
      }
    }

    const submitValidatedContactLocal = () => {
      // console.log(currentContact.value)
      submitValidatedUser(currentContact.value)
          .then(response => {
            // console.log(response.data)
            currentContact.value = response.data

            emit('update:contact', selectedContact)
            emit('input', selectedContact)
            emit('change')

            contactModalShow.value = false
          })

    }

    const update = users => {
      localModel.value = users
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    if (Array.isArray(props.user)) {
      localModel.value = props.user.map(u=>u.id)
    } else {
      localModel.value = props.user
    }

    return {
      // Components

      // Data
      localModel,
      currentContact,
      isContactEdit,
      contactModalShow,

      // Computed

      // Methods
      newSelectedContact,
      selectContact,
      submitValidatedContactLocal,
      update,
    }
  }
  ,
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

.form-group {
  margin-bottom: 0
}
</style>