<template>
  <div>
    <b-card>

      <!--      Type + title-->
      <b-row>
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <b-dropdown
              no-caret
              variant="outline-dark"
              class="align-self-start dropdown-icon mr-1"
              style="width: 40px; height: 37px;"
              :disabled="!isEditable"
          >
            <template #button-content>
              <icon
                  :icon="currentEventIcon.icon"
                  size="2x"
              />
            </template>
            <b-dropdown-item
                v-for="eventIcon in eventIcons"
                @click="eventTypeChange(eventIcon.text)"
            >
              <div class="d-flex">
                <div style="width: 30px;">
                  <icon
                      :icon="eventIcon.icon"
                      size="lg"
                  />
                </div>
                <div>{{ capitalize($tc(eventIcon.text)) }}</div>
              </div>
            </b-dropdown-item>
          </b-dropdown>

          <input-field
              :name="capitalize($t('addTitle'))"
              :display-label="false"
              :rules="eventLocal.type != 'holiday'?'required':''"
              :model.sync="eventLocal.title"
              :disabled="isDisabled"
              :with-template="true"
              template-type="event"
              class="my-auto w-100"

              @templateSelected="selectTemplate"
          />

        </b-col>
        <b-col
            md="6"
            class="mt-1 text-right d-flex justify-content-end"
        >
          <div
              v-if="!isTemplate"
              class="my-auto"
              @mouseover="isDeleteIconHovered = true"
              @mouseleave="isDeleteIconHovered = false"
              :class="{'text-danger cursor-pointer': isDeletable && isDeleteIconHovered}"
              @click="isDeletable?$emit('removeEvent'):false"
          >
            <icon
                icon="trash-alt"
                :disabled="!isDeletable"
            />
          </div>
        </b-col>
      </b-row>

      <!--      Additional members-->
      <b-row>
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              class="mr-1 my-auto"
              style="width: 35px"
          >
            <icon
                icon="user-plus"
                size="2x"
            />
          </div>
          <users-input
              ref="usersInput"
              :placeholder="$t('Inviter des participants')"
              :display-label="false"
              :user.sync="eventLocal.additionalMembers"
              :multiple="true"
              :isIndividualOnly="true"
              :is-disabled="isDisabled || eventLocal.type == 'holiday'"
              :options="autocompleteUsers"
              reduce="id"
              :allow-add="false"
              :display-companies="true"
              style="max-width: 253px"

              @input="additionalMembersUpdated"
          />
        </b-col>

        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              v-if="['discussion'].includes(eventLocal.type)"
              class="my-auto"
          >
            <div class="d-flex">
              <b-form-checkbox
                  v-model="eventLocal.isOnlineMeeting"
                  class="custom-control-primary my-auto"
                  switch
                  :disabled="isDisabled"
              />
              <span class="my-auto">{{ capitalize($t('onlineMeeting')) }}</span>
            </div>

            <div
                v-if="eventLocal.isOnlineMeeting"
            >
              {{ capitalize($t('visioLink')) }}
              <b-link
                  target="_blank"
                  :disabled="eventLocal.visioLink==null ||eventLocal.visioLink == ''"
                  v-b-tooltip="eventLocal.visioLink"
                  :href="eventLocal.visioLink"
                  class="ml-50"
              >
                <icon
                    icon="external-link-alt"
                />
              </b-link>
            </div>
          </div>
          <div v-else-if="eventLocal.type == 'call'">
            <switch-field
                name="outgoingCall"
                :model.sync="eventLocal.outgoingCall"
            />

            <div
                id="popover-reactive-1"
                class="text-primary cursor-pointer"
            >
              <span v-if="eventLocal.callResultType == null">Résultat de l’appel</span>
              <span v-else>{{ capitalize($t(eventLocal.callResultType)) }}</span>
              <icon
                  class="ml-50"
                  icon="caret-down"
              />
            </div>
            <b-popover
                ref="popover"
                target="popover-reactive-1"
                triggers="click"
                placement="auto"
                container="my-container"
            >
              <div
                  v-for="callResultType in listOfCallResultTypes"
                  class="cursor-pointer py-50"
                  @click="callResultClick(callResultType);$root.$emit('bv::hide::popover', 'popover-reactive-1')"
              >
                {{ callResultType.display }}
              </div>
            </b-popover>
          </div>
        </b-col>
      </b-row>

      <!--      Start date-->
      <b-row
          v-if="eventLocal.type != 'absence' && eventLocal.subType != 'holiday' && !isTemplate"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >

          <div
              class="overflow-hidden mr-1 my-auto cursor-pointer"
              :class="{'text-primary' : isStartDateMagnet}"
              style="flex: none; width: 35px;"

              @click="isStartDateMagnet=!isStartDateMagnet"
          >
            <icon
                icon="magnet"
                size="2x"
            />
          </div>

          <div
              class=""
              style="flex: none; width: 150px;"
          >
            <date-field
                name="startDate"
                rules="required"
                :display-label="false"
                :enableTime="false"
                :model.sync="startDateLocal"
                :disabled="isDisabled"
                class="w-100"
            />
          </div>

          <div
              class="ml-1"
              style="flex: none; width: 100px;"
          >
            <flat-pickr
                v-if="!isDisabled"
                v-model="startTimeLocal"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', minuteIncrement: 1,time_24hr: true, inline: true}"
            />
            <div
                v-else
                class="d-flex h-100"
            >
              <span class="my-auto">{{ startTimeLocal }}</span>
            </div>

          </div>

          <div
              class="ml-1"
              style="flex: initial; "
          >
            <div
                v-if="isTimeZoneFieldsDisplayed"
                style="max-width: 240px"
            >
              <select2
                  :model.sync="startTimeZoneLocal"
                  :options="timeZoneOptions"
                  :clearable="false"
                  label="name"
                  reduce="value"
                  class=""
              />
            </div>
          </div>

          <div
              v-if="!isDisabled"
              class="my-auto"
              style="flex: initial;"
          >
            <div
                class="w-100 ml-1 text-primary cursor-pointer d-flex"

                @click="isTimeZoneFieldsDisplayed= !isTimeZoneFieldsDisplayed"
            >
              <icon
                  icon="globe"
                  class="my-auto"
              />
              <div class="ml-50 my-auto text-nowrap">
                Fuseaux horaires
              </div>
            </div>
          </div>


        </b-col>
      </b-row>

      <!--      End date-->
      <b-row
          v-if="eventLocal.type != 'absence' && eventLocal.subType != 'holiday' && !isTemplate"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >

          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                v-if="!['call', 'discussion', 'mail'].includes(eventLocal.type)"
                icon="clock"
                size="2x"
            />
          </div>

          <div
              class=""
              style="flex: none; width: 150px;"
          >
            <date-field
                v-if="!['call', 'discussion', 'mail'].includes(eventLocal.type)"
                name="endDate"
                rules="required"
                :display-label="false"
                :enableTime="false"
                :model.sync="endDateLocal"
                :disabled="isDisabled"
                :min-date="startDateLocal"
                class="w-100"
            />
          </div>

          <div
              class="ml-1"
              style="flex: none; width: 100px;"
          >

            <flat-pickr
                v-if="!['call', 'discussion', 'mail'].includes(eventLocal.type) && !isDisabled"
                v-model="endTimeLocal"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', minuteIncrement: 1,time_24hr: true, inline: true}"
            />
            <div
                v-else-if="!['call', 'discussion', 'mail'].includes(eventLocal.type) && isDisabled"
                class="d-flex h-100"
            >
              <span class="my-auto">{{ endTimeLocal }}</span>
            </div>

            <flat-pickr
                v-if="['call', 'discussion', 'mail'].includes(eventLocal.type) && !isDisabled"
                v-model="selectedMinute"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', minuteIncrement: 1,time_24hr: true, inline: true}"
            />

            <div
                v-else-if="['call', 'discussion', 'mail'].includes(eventLocal.type) && isDisabled"
                class="d-flex h-100"
            >
              <span class="my-auto">{{ selectedMinute }}</span>
            </div>


          </div>

          <div
              class="ml-1"
              style="flex: initial; "
          >
            <div
                v-if="isTimeZoneFieldsDisplayed && !['call', 'discussion', 'mail'].includes(eventLocal.type)"
                style="max-width: 240px"
            >
              <select2
                  :model.sync="endTimeZoneLocal"
                  :options="timeZoneOptions"
                  :clearable="false"
                  label="name"
                  reduce="value"
                  class=""
              />
            </div>
          </div>

          <div
              v-if="!['call', 'discussion'].includes(eventLocal.type) && !isDisabled"
              class="my-auto"
              style="flex: initial;"
          >
            <div
                class="w-100 ml-1 text-primary cursor-pointer d-flex"

                @click="isTimeZoneFieldsDisplayed= !isTimeZoneFieldsDisplayed"
            >
              <icon
                  icon="globe"
                  class="my-auto"
              />
              <div class="ml-50 my-auto text-nowrap">
                Fuseaux horaires
              </div>
            </div>
          </div>


        </b-col>
      </b-row>
      <!--      End date time if template -->
      <b-row
          v-if="['call', 'discussion', 'mail'].includes(eventLocal.type) && !isDisabled && isTemplate"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="hourglass"
                size="2x"
            />
          </div>

          <div
              style="flex: none; width: 100px;"
          >
            <flat-pickr
                v-model="selectedMinute"
                class="form-control"
                :config="{ enableTime: true, noCalendar: true, dateFormat: 'H:i', minuteIncrement: 1,time_24hr: true, inline: true}"
            />
          </div>
        </b-col>
      </b-row>

      <!--      Date absence-->
      <b-row
          v-if="!isTemplate && (eventLocal.type == 'absence' || eventLocal.subType == 'holiday')"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >

          <div
              class="overflow-hidden mr-1 my-auto cursor-pointer"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="clock"
                size="2x"
            />
          </div>

          <div class="w-100">
            <date-field
                name="absenceDates"
                :display-label="false"
                :enable-time="false"
                :model.sync="absenceDates"
                :disabled="isDisabled"
                :range="true"
                class="w-100"
            />
          </div>

        </b-col>
      </b-row>
      <b-row
          v-if="eventLocal.type == 'absence' || eventLocal.subType == 'holiday'"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto cursor-pointer"
              style="flex: none; width: 35px;"
          >
          </div>

          <div class="w-100 mr-5">
            <select2
                :model.sync="startMorningAfternoon"
                :options="startMorningAfternoonOptions"
                label="display"
                reduce="value"
                :disabled="isDisabled"
            />
          </div>

          <div class="w-100 mr-5">
            <select2
                :model.sync="endMorningAfternoon"
                :options="endMorningAfternoonOptions"
                label="display"
                reduce="value"
                :disabled="isDisabled"
            />
          </div>
        </b-col>
      </b-row>

      <!--      Project-->
      <b-row v-if="!('workFloId' in $router.history.current.params)">
        <b-col
            :md="eventLocal.workflow == null?12:6"
            class="mt-1"
        >
          <div class="d-flex">
            <div
                class="overflow-hidden mr-1 my-auto"
                style="flex: none; width: 35px;"
            >
              <icon
                  icon="tag"
                  size="2x"
              />
            </div>

            <div
                class="my-auto mr-1"
                style="flex: initial"
            >
              <b-badge
                  v-if="selectedProjectLocal == null"
                  pill
                  variant="light-secondary"
              >{{ capitalize($t('category')) }}
              </b-badge>
              <b-badge
                  v-else-if="projectOptions.find(o=>o.i == selectedProjectLocal).category == 'workFlo'"
                  pill
                  variant="light-primary"
              >{{ capitalize($t('opportunity')) }}
              </b-badge>
              <b-badge
                  v-else-if="projectOptions.find(o=>o.i == selectedProjectLocal).category == 'workflow'"
                  pill
                  variant="light-info"
              >{{ capitalize($t('workflow')) }}
              </b-badge>
            </div>


            <div
                class="my-auto flex-grow-1"
                style="flex: initial"
            >
              <div class="d-flex">
                <select2
                    :model.sync="selectedProjectLocal"
                    :options="projectOptions"
                    :dropdown-parent-size-multiply="1"
                    label="display"
                    reduce="i"
                    :disabled="isDisabled"
                />
                <icon
                    v-if="selectedProjectLocal != null && eventLocal.workflow == null"
                    icon="info-circle"
                    class="ml-1 text-info my-auto cursor-pointer"

                    @click="displayProjectPropositions = !displayProjectPropositions"
                />

                <icon
                    v-if="eventLocal.workFlo != null"
                    icon="external-link-alt"
                    class="ml-50 text-info my-auto cursor-pointer"

                    @click="goToNewTab($router.resolve({name: 'WorkFlo view', params: { workFloId: (isObject(eventLocal.workFlo)?eventLocal.workFlo.id:eventLocal.workFlo) }}).href)"
                />

                <icon
                    v-else-if="eventLocal.workflow != null"
                    icon="external-link-alt"
                    class="ml-50 text-info my-auto cursor-pointer"

                    @click="goToNewTab($router.resolve({name: 'Workflow view', params: { workflowId: (isObject(eventLocal.workflow)?eventLocal.workflow.id:eventLocal.workflow) }}).href)"
                />
              </div>
            </div>

          </div>
        </b-col>

        <b-col
            md="6"
            class="mt-1"
        >
          <div v-if="eventLocal.workflow != null">
            <select2
                :model.sync="eventLocal.phase"
                :display-label="false"
                name="phase"
                :options="autocompletePhases"
                label="name"
                :placeholder="$t('Choose a phase')"
                :disabled="isDisabled"
            />
          </div>
        </b-col>
      </b-row>
      <b-row v-if="!('workFloId' in $router.history.current.params) && displayProjectPropositions==true && eventLocal.additionalMembers.length != 0">
        <b-col
            md="12"
        >
          <div class="d-flex h-100">
            <div
                v-for="projectProposition in projectPropositions"
                class="text-truncate mx-50 my-auto cursor-pointer"
                style="flex: 1 1 0;"

                @click="selectedProjectLocal = projectProposition.i"
            >
              <b-badge
                  v-if="projectProposition.category == 'workFlo'"
                  variant="light-primary"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="projectProposition.display"
              >
                {{ projectProposition.display }}
              </b-badge>
              <b-badge
                  v-else-if="projectProposition.category == 'workflow'"
                  variant="light-info"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="projectProposition.display"
              >
                {{ projectProposition.display }}
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>

      <!--      Place-->
      <b-row
          v-if="eventLocal.type == 'intervention'"
      >
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="map-marker-alt"
                size="2x"
            />
          </div>

          <div class="my-auto w-100">
            <field-address-by-query
                name="place"
                :display-label="false"
                :model.sync="eventLocal.place"
                :displayComplement="false"
            />
          </div>
        </b-col>

        <b-col
            md="6"
            class="mt-1"
        >
          <div class="d-flex h-100">
            <div
                v-for="addressProposition in addressPropositions"
                class="text-truncate mx-50 my-auto cursor-pointer"
                style="flex: 1 1 0;"

                @click="eventLocal.place = addressProposition._display"
            >
              <b-badge
                  variant="light-secondary"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="addressProposition._display"
              >
                {{ addressProposition._display }}
              </b-badge>
            </div>
          </div>

        </b-col>
      </b-row>

      <!--      Trip-->
      <b-row
          v-if="eventLocal.type == 'trip'"
      >
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="map-marker-alt"
                size="2x"
            />
          </div>

          <div class="my-auto w-100">
            <field-address-by-query
                :name="capitalize($t('startPlace'))"
                :model.sync="eventLocal.startPlace"
                :display-label="false"
                :isRequired="true"
                :displayComplement="false"
                ref="startPlaceRef"
                class="w-100"
            />
          </div>

          <icon
              icon="exchange-alt"
              class="mt-50 mx-2 cursor-pointer"
              @click.native="intervertAddresses"
          />

          <div class="my-auto w-100">
            <field-address-by-query
                :name="capitalize($t('endPlace'))"
                :model.sync="eventLocal.endPlace"
                :display-label="false"
                :isRequired="true"
                :displayComplement="false"
                ref="endPlace"
                class="w-100"
            />
          </div>


        </b-col>

        <b-col md="12">
          <div class="d-flex justify-content-between h-100">
            <div
                v-for="addressProposition in addressTripPropositions"
                class="text-truncate mx-50 py-50 my-auto cursor-pointer"
                style=""

                @click="selectTripAddresses(addressProposition)"
            >
              <b-badge
                  variant="light-secondary"
                  class="text-truncate fill"
                  v-b-tooltip.hover.top="addressProposition.startPlace._display + ' 🠮 ' + addressProposition.endPlace._display"
              >
                {{ addressProposition.startPlace._display }} 🠮 {{ addressProposition.endPlace._display }}
              </b-badge>
            </div>
          </div>
        </b-col>
      </b-row>
      <b-row v-if="eventLocal.type == 'trip'">
        <b-col
            md="12"
            class="d-flex mt-1"
        >
          <b-form-checkbox
              v-model="eventLocal.isTripWithPersonalVehicle"
              class="custom-control-primary form-group"
              switch
          >
          </b-form-checkbox>
          <span class="my-auto">{{ capitalize($t('tripWithPersonalVehicle')) }}</span>
        </b-col>
      </b-row>

      <!--      Discussion Platform-->
      <b-row
          v-if="eventLocal.type == 'discussion' && eventLocal.isOnlineMeeting == false"
      >
        <b-col
            md="6"
            class="d-flex mt-1"
        >
          <div
              class="overflow-hidden mr-1 my-auto"
              style="flex: none; width: 35px;"
          >
            <icon
                icon="comment-dots"
                size="2x"
            />
          </div>

          <select2
              :model.sync="eventLocal.discussionPlatform"
              :options="discussionOptions"
              :clearable="false"
              class=""
          />
        </b-col>

      </b-row>

      <!--      Add reminder-->
      <b-row v-if="!isTemplate">
        <b-col
            md="12"
            class="mt-1"
        >
          <div
              class="d-flex"
              style="padding-left: 10px;"
          >
            <b-form-checkbox
                :disabled="isDisabled"
                v-model="addReminder"
            >
              {{ capitalize($t('createReminder')) }}
            </b-form-checkbox>

            <div
                v-if="addReminder==true"
                class="ml-1"
            >
              <div
                  id="popover-reactive-reminder-type"
                  class="d-flex text-primary cursor-pointer"
              >
                <div :key="componentKey">
                  <span v-if="eventLocal._reminderType == null">{{ capitalize($t('type')) }}</span>
                  <span v-else>{{ capitalize($tc(eventLocal._reminderType)) }}</span>
                  <icon
                      class="ml-50"
                      icon="caret-down"
                  />
                </div>
              </div>
              <b-popover
                  ref="popover"
                  target="popover-reactive-reminder-type"
                  triggers="click"
                  placement="auto"
                  container="my-container"
              >
                <div
                    v-for="reminderType in listOfReminderTypes"
                    class="cursor-pointer py-50"
                    @click="eventLocal._reminderType = reminderType.text;componentKey++;$root.$emit('bv::hide::popover', 'popover-reactive-reminder-type')"
                >
                  <icon :icon="reminderType.icon"/>
                  {{ capitalize($tc(reminderType.text)) }}
                </div>
              </b-popover>
            </div>

            <div
                v-if="addReminder==true"
                class="ml-1"
            >
              {{ $t('for a follow-up for') }}
            </div>

            <div
                v-if="addReminder==true"
                class="ml-1"
            >
              <div
                  id="popover-reactive-reminder-period"
                  class="d-flex text-primary cursor-pointer"
              >
                <div :key="componentKey">
                  <span v-if="eventLocal._reminderPeriodDisplay == null">{{ capitalize($t('period')) }}</span>
                  <span v-else>{{ eventLocal._reminderPeriodDisplay }}</span>
                </div>
                <icon
                    class="ml-50"
                    icon="caret-down"
                />
              </div>
              <b-popover
                  ref="popover"
                  target="popover-reactive-reminder-period"
                  triggers="click"
                  placement="auto"
                  container="my-container"
              >
                <div
                    v-for="followUpPeriod in followUpPeriods"
                    class="cursor-pointer py-50"
                    @click="changeReminderPeriod(followUpPeriod);$root.$emit('bv::hide::popover', 'popover-reactive-reminder-period')"
                >
                  {{ followUpPeriod.display }}
                </div>
              </b-popover>
            </div>

          </div>


        </b-col>
      </b-row>
      <b-row v-if="!isTemplate && displayReminderPeriodCalendar">
        <b-col
            md="12"
            class="mt-1"
        >
          <date-field
              name="date"
              :display-label="false"
              :enableTime="false"
              :model.sync="eventLocal._reminderPeriod"
              class="w-100"
          />
        </b-col>
      </b-row>

      <!--      Close reminder-->
      <b-row
          v-if="eventLocal.reminder != null"
          class="d-none"
      >
        <b-col
            md="12"
            class="mt-1"
        >
          <div class="d-flex">
            <b-form-checkbox
                v-model="closeReminderLocal"
                class="custom-control-primary my-auto"
                switch
            />
            <span class="my-auto">{{ capitalize($t('closedReminder')) }}</span>
          </div>
        </b-col>
      </b-row>

      <!--      Tabs-->
      <event2-tabs
          :item="eventLocal"
          :is-disabled="isDisabled"
          class="mt-2"
      />

    </b-card>

  </div>
</template>

<script>
import { ref, computed, watch }                     from '@vue/composition-api'
import { capitalize }                               from '../../../utils/filter'
import i18n                                         from '../../../libs/i18n'
import InputField                                   from '../../input/Input.vue'
import FieldSelect                                  from '../../input/Select2.vue'
import Select2                                      from '../../input/Select2.vue'
import useAPI                                       from '../../../utils/useAPI'
import DateField                                    from '../../input/Date.vue'
import moment                                       from 'moment'
import { clone, getUserData, goToNewTab, isObject } from '../../../utils/utils'
import store                                        from '../../../store'
import FieldAddressByQuery                          from '../../input/AddressAutosuggest.vue'
import Event2Tabs                                   from './components/Event2Tabs.vue'
import SwitchField                                  from '../../input/Switch.vue'
import ability                                      from '../../../libs/acl/ability'
import flatPickr                                    from 'vue-flatpickr-component'
import Contact2                                     from '../../input/Contact2.vue'
import UsersInput                                   from '../../input/UsersInput.vue'
import router                                       from '../../../router'
import TemplateInput                                from '../../input/TemplateInput.vue'

const timeZoneOptions = [
  { name: '(GMT -12:00) Eniwetok, Kwajalein', value: '-12:00' },
  { name: '(GMT -11:00) Midway Island, Samoa', value: '-11:00' },
  { name: '(GMT -10:00) Hawaii', value: '-10:00' },
  { name: '(GMT -9:30) Taiohae', value: '-09:50' },
  { name: '(GMT -9:00) Alaska', value: '-09:00' },
  { name: '(GMT -8:00) Pacific Time (US &amp; Canada)', value: '-08:00' },
  { name: '(GMT -7:00) Mountain Time (US &amp; Canada)', value: '-07:00' },
  { name: '(GMT -6:00) Central Time (US &amp; Canada), Mexico City', value: '-06:00' },
  { name: '(GMT -5:00) Eastern Time (US &amp; Canada), Bogota, Lima', value: '-05:00' },
  { name: '(GMT -4:30) Caracas', value: '-04:50' },
  { name: '(GMT -4:00) Atlantic Time (Canada), Caracas, La Paz', value: '-04:00' },
  { name: '(GMT -3:30) Newfoundland', value: '-03:50' },
  { name: '(GMT -3:00) Brazil, Buenos Aires, Georgetown', value: '-03:00' },
  { name: '(GMT -2:00) Mid-Atlantic', value: '-02:00' },
  { name: '(GMT -1:00) Azores, Cape Verde Islands', value: '-01:00' },
  { name: '(GMT) Western Europe Time, London, Lisbon, Casablanca', value: '+00:00' },
  { name: '(GMT +1:00) Brussels, Copenhagen, Madrid, Paris', value: '+01:00' },
  { name: '(GMT +2:00) Kaliningrad, South Africa', value: '+02:00' },
  { name: '(GMT +3:00) Baghdad, Riyadh, Moscow, St. Petersburg', value: '+03:00' },
  { name: '(GMT +3:30) Tehran', value: '+03:50' },
  { name: '(GMT +4:00) Abu Dhabi, Muscat, Baku, Tbilisi', value: '+04:00' },
  { name: '(GMT +4:30) Kabul', value: '+04:50' },
  { name: '(GMT +5:00) Ekaterinburg, Islamabad, Karachi, Tashkent', value: '+05:00' },
  { name: '(GMT +5:30) Bombay, Calcutta, Madras, New Delhi', value: '+05:50' },
  { name: '(GMT +5:45) Kathmandu, Pokhara', value: '+05:75' },
  { name: '(GMT +6:00) Almaty, Dhaka, Colombo', value: '+06:00' },
  { name: '(GMT +6:30) Yangon, Mandalay', value: '+06:50' },
  { name: '(GMT +7:00) Bangkok, Hanoi, Jakarta', value: '+07:00' },
  { name: '(GMT +8:00) Beijing, Perth, Singapore, Hong Kong', value: '+08:00' },
  { name: '(GMT +8:45) Eucla', value: '+08:75' },
  { name: '(GMT +9:00) Tokyo, Seoul, Osaka, Sapporo, Yakutsk', value: '+09:00' },
  { name: '(GMT +9:30) Adelaide, Darwin', value: '+09:50' },
  { name: '(GMT +10:00) Eastern Australia, Guam, Vladivostok', value: '+10:00' },
  { name: '(GMT +10:30) Lord Howe Island', value: '+10:50' },
  { name: '(GMT +11:00) Magadan, Solomon Islands, New Caledonia', value: '+11:00' },
  { name: '(GMT +11:30) Norfolk Island', value: '+11:50' },
  { name: '(GMT +12:00) Auckland, Wellington, Fiji, Kamchatka', value: '+12:00' },
  { name: '(GMT +12:45) Chatham Islands', value: '+12:75' },
  { name: '(GMT +13:00) Apia, Nukualofa', value: '+13:00' },
  { name: '(GMT +14:00) Line Islands, Tokelau', value: '+14:00' }
]

const timeOptions = [
  '00:00',
  '00:30',
  '01:00',
  '01:30',
  '02:00',
  '02:30',
  '03:00',
  '03:30',
  '04:00',
  '04:30',
  '05:00',
  '05:30',
  '06:00',
  '06:30',
  '07:00',
  '07:30',
  '08:00',
  '08:30',
  '09:00',
  '09:30',
  '10:00',
  '10:30',
  '11:00',
  '11:30',
  '12:00',
  '12:30',
  '13:00',
  '13:30',
  '14:00',
  '14:30',
  '15:00',
  '15:30',
  '16:00',
  '16:30',
  '17:00',
  '17:30',
  '18:00',
  '18:30',
  '19:00',
  '19:30',
  '20:00',
  '20:30',
  '21:00',
  '21:30',
  '22:00',
  '22:30',
  '23:00',
  '23:30'
]

const minuteOptions = [
  {
    'display': '1 min',
    'value': 1
  },
  {
    'display': '2 min',
    'value': 2
  },
  {
    'display': '3 min',
    'value': 3
  },
  {
    'display': '4 min',
    'value': 4
  },
  {
    'display': '5 min',
    'value': 5
  },
  {
    'display': '6 min',
    'value': 6
  },
  {
    'display': '7 min',
    'value': 7
  },
  {
    'display': '8 min',
    'value': 8
  },
  {
    'display': '9 min',
    'value': 9
  },
  {
    'display': '10 min',
    'value': 10
  },
  {
    'display': '11 min',
    'value': 11
  },
  {
    'display': '12 min',
    'value': 12
  },
  {
    'display': '13 min',
    'value': 13
  },
  {
    'display': '14 min',
    'value': 14
  },
  {
    'display': '15 min',
    'value': 15
  },
  {
    'display': '16 min',
    'value': 16
  },
  {
    'display': '17 min',
    'value': 17
  },
  {
    'display': '18 min',
    'value': 18
  },
  {
    'display': '19 min',
    'value': 19
  },
  {
    'display': '20 min',
    'value': 20
  },
  {
    'display': '21 min',
    'value': 21
  },
  {
    'display': '22 min',
    'value': 22
  },
  {
    'display': '23 min',
    'value': 23
  },
  {
    'display': '24 min',
    'value': 24
  },
  {
    'display': '25 min',
    'value': 25
  },
  {
    'display': '26 min',
    'value': 26
  },
  {
    'display': '27 min',
    'value': 27
  },
  {
    'display': '28 min',
    'value': 28
  },
  {
    'display': '29 min',
    'value': 29
  },
  {
    'display': '30 min',
    'value': 30
  },
  {
    'display': '31 min',
    'value': 31
  },
  {
    'display': '32 min',
    'value': 32
  },
  {
    'display': '33 min',
    'value': 33
  },
  {
    'display': '34 min',
    'value': 34
  },
  {
    'display': '35 min',
    'value': 35
  },
  {
    'display': '36 min',
    'value': 36
  },
  {
    'display': '37 min',
    'value': 37
  },
  {
    'display': '38 min',
    'value': 38
  },
  {
    'display': '39 min',
    'value': 39
  },
  {
    'display': '40 min',
    'value': 40
  },
  {
    'display': '41 min',
    'value': 41
  },
  {
    'display': '42 min',
    'value': 42
  },
  {
    'display': '43 min',
    'value': 43
  },
  {
    'display': '44 min',
    'value': 44
  },
  {
    'display': '45 min',
    'value': 45
  },
  {
    'display': '46 min',
    'value': 46
  },
  {
    'display': '47 min',
    'value': 47
  },
  {
    'display': '48 min',
    'value': 48
  },
  {
    'display': '49 min',
    'value': 49
  },
  {
    'display': '50 min',
    'value': 50
  },
  {
    'display': '51 min',
    'value': 51
  },
  {
    'display': '52 min',
    'value': 52
  },
  {
    'display': '53 min',
    'value': 53
  },
  {
    'display': '54 min',
    'value': 54
  },
  {
    'display': '55 min',
    'value': 55
  },
  {
    'display': '56 min',
    'value': 56
  },
  {
    'display': '57 min',
    'value': 57
  },
  {
    'display': '58 min',
    'value': 58
  },
  {
    'display': '59 min',
    'value': 59
  }
]

const discussionOptions = [
  'SMS', 'Présentiel', 'WhatsApp', 'LinkedIn', 'Instagram', 'X', 'Facebook'
]

export default {
  components: {
    TemplateInput,
    Contact2,
    SwitchField,
    FieldAddressByQuery,
    DateField,
    Select2,
    FieldSelect,
    InputField,
    Event2Tabs,
    flatPickr,
    UsersInput,
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // console.log(clone(props.event))
    // console.log(clone(props.viewOnly))
    // console.log(moment(props.event.startDate).format('YYYY-MM-DD'))
    // console.log(moment(props.event.startDate).format('HH:mm'))
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const eventIcons = ref([
      {
        icon: 'user-circle',
        text: 'work'
      },
      {
        icon: 'comments',
        text: 'discussion'
      },
      {
        icon: 'phone',
        text: 'call'
      },
      {
        icon: 'envelope',
        text: 'mail'
      },
      {
        icon: 'briefcase',
        text: 'intervention'
      },
      {
        icon: 'suitcase-rolling',
        text: 'trip'
      },
      {
        icon: 'glasses',
        text: 'absence'
      },
      {
        icon: 'umbrella-beach',
        text: 'holiday'
      },
    ])
    const startDateLocal = ref(moment(props.event.startDate).format('YYYY-MM-DD'))
    const startTimeLocal = ref()
    const startTimeZoneLocal = ref(timeZoneOptions.find(tz => tz.value == '+01:00'))
    const endDateLocal = ref(moment(props.event.endDate).format('YYYY-MM-DD'))
    const endTimeLocal = ref(moment(props.event.endDate).format('HH:mm'))
    const endTimeZoneLocal = ref(timeZoneOptions.find(tz => tz.value == '+01:00'))
    const isTimeZoneFieldsDisplayed = ref(false)
    const isStartDateMagnet = ref((!('id' in props.event) && props.event.writtenEmail == null))
    const initialStartDate = ref(props.event.startDate)
    const initialEndDate = ref(props.event.endDate)
    const selectedProjectLocal = ref()
    const projectPropositions = ref([])
    const addressPropositions = ref([])
    const addressTripPropositions = ref([])
    const selectedMinute = ref(null)
    const startPlaceRef = ref()
    const endPlace = ref(null)
    const absenceDates = ref([])
    const startMorningAfternoonOptions = ref([
      {
        value: 'morning',
        display: capitalize(i18n.t('earlyMorning'))
      }, {
        value: 'afternoon',
        display: capitalize(i18n.t('earlyAfternoon'))
      }
    ])
    const endMorningAfternoonOptions = ref([
      {
        value: 'morning',
        display: capitalize(i18n.t('lateMorning'))
      }, {
        value: 'afternoon',
        display: capitalize(i18n.t('lateAfternoon'))
      }
    ])
    const startMorningAfternoon = ref('morning')
    const endMorningAfternoon = ref('afternoon')
    const listOfCallResultTypes = ref([
      {
        display: capitalize(i18n.t('successful call')),
        value: 'successful call'
      },
      {
        display: capitalize(i18n.t('no response')),
        value: 'no response'
      },
      {
        display: capitalize(i18n.t('busy')),
        value: 'busy'
      },
      {
        display: capitalize(i18n.t('wrong number')),
        value: 'wrong number'
      },
      {
        display: capitalize(i18n.t('voice message')),
        value: 'voice message'
      },
      {
        display: capitalize(i18n.t('interrupted call')),
        value: 'interrupted call'
      },
    ])
    const displayProjectPropositions = ref(true)
    const isDeleteIconHovered = ref(false)
    const closeReminderLocal = ref(null)
    const addReminder = ref(false)
    const listOfReminderTypes = ref([
      {
        icon: 'phone',
        text: 'call'
      },
      {
        icon: 'envelope',
        text: 'mail'
      },
      {
        icon: 'comments',
        text: 'discussion'
      },
      {
        icon: 'user-circle',
        text: 'work'
      },])
    const displayReminderPeriodCalendar = ref(false)
    const componentKey = ref(0)
    const usersInput = ref()

    const isInitialChange = ref(true)
    const slotTimeDuration = ref(15)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      autocompleteUsers,
      events3,
      autocompleteWorkFlos,
      autocompleteWorkflows,
      autocompletePhases
    } = useAPI()

    const eventLocal = computed({
      get () {
        return props.event
      },
      set (val) {
        emit('update:event', val)
      }
    })

    const currentEventIcon = computed(() => {
      if (!('type' in eventLocal.value) && eventLocal.value.type == null) {
        return eventIcons.value[0]
      } else {
        return eventIcons.value.filter(ei => ei.text == eventLocal.value.type)[0]
      }
    })

    const lastEventBefore = computed(() => {
      let searchedDate = moment(clone(initialStartDate.value))
      // console.log(clone(events3.value))
      // let currentUserEvents = events3.value.filter(e => e._employee == getUserData()._display)
      // let currentUserEvents = events3.value.filter(e => e._calendarEvent.extendedProps.concernedUsers.id == getUserData().id)
      let currentUserEvents = events3.value.filter(e => {
        return e._calendarEvent.extendedProps.concernedUsers.some(u => u.id == getUserData().id)
      })
      let lastEventBeforeLocal = null

      // console.log(clone(currentUserEvents))

      // currentUserEvents.forEach(e => {
      //   if (
      //       moment(e.endDate).format('YYYY-MM-DD') == searchedDate.format('YYYY-MM-DD') &&
      //       (
      //           lastEventBeforeLocal == null ||
      //           (
      //               moment(e.endDate).isBefore(searchedDate) &&
      //               moment(e.endDate).isAfter(moment(lastEventBefore.endDate))
      //           )
      //       )
      //   ) {
      //     // console.log(clone(e))
      //     lastEventBeforeLocal = e
      //   }
      // })
      currentUserEvents.forEach(e => {
        if (
            moment(e.endDate).format('YYYY-MM-DD') == searchedDate.format('YYYY-MM-DD') &&
            (
                moment(e.endDate).isBefore(searchedDate) ||
                moment(e.endDate).format('HH:mm') == searchedDate.format('HH:mm')
                // searchedDate.isAfter(moment(e.endDate))
            ) &&
            (
                lastEventBeforeLocal == null ||
                moment(lastEventBeforeLocal.endDate).isBefore(moment(e.endDate))
            )
        ) {
          // console.log(clone(e))
          lastEventBeforeLocal = e
        }
      })

      return lastEventBeforeLocal
    })

    const timeEndOptions = computed(() => {
      if (moment(startDateLocal.value).format('YYYY-MM-DD') == moment(endDateLocal.value).format('YYYY-MM-DD')) {
        return timeOptions.filter(t => moment(startDateLocal.value + ' ' + t) > moment(startDateLocal.value + ' ' + startTimeLocal.value))
      } else {
        return timeOptions
      }
    })

    const projectOptions = computed(() => {
      let output = []

      let index = 0
      autocompleteWorkFlos.value.forEach(w => {
        output.push({
          i: ++index,
          category: 'workFlo',
          workFloId: w.id,
          workflowId: null,
          display: w._display
        })
      })
      autocompleteWorkflows.value.forEach(wfw => {
        output.push({
          i: ++index,
          category: 'workflow',
          workFloId: null,
          workflowId: wfw.id,
          display: wfw._display
        })
      })

      // console.log(output)

      return output.sort((a, b) => a.display < b.display ? -1 : 1)
    })

    const isDeletable = computed(() => {
      if (
          eventLocal.value.isValidated != true &&
          (
              (
                  'logs' in eventLocal.value &&
                  eventLocal.value.logs.length >= 1 &&
                  eventLocal.value.logs[0].by.id == getUserData().id
              ) ||
              (
                  eventLocal.isValidated == true &&
                  ability.can('manage', 'all')
              )
          )
      ) {
        return true
      } else {
        return false
      }
    })

    const followUpPeriods = computed(() => {
      if (eventLocal.value.startDate != null) {
        let now = moment(clone(eventLocal.value.startDate))
        let tomorrow = moment(clone(now)).add(1, 'days')
        let oneWeek = moment(clone(now)).add(1, 'weeks')
        let twoWeek = moment(clone(now)).add(2, 'weeks')
        let oneMonth = moment(clone(now)).add(1, 'months')
        let twoMonth = moment(clone(now)).add(2, 'months')
        let threeMonth = moment(clone(now)).add(3, 'months')

        if ([0, 6].includes(tomorrow.day())) {
          tomorrow.day() == 0 ? tomorrow.add(1, 'days') : tomorrow.add(2, 'days')
        }
        if ([0, 6].includes(oneWeek.day())) {
          oneWeek.day() == 0 ? oneWeek.add(1, 'days') : oneWeek.add(2, 'days')
        }
        if ([0, 6].includes(twoWeek.day())) {
          twoWeek.day() == 0 ? twoWeek.add(1, 'days') : twoWeek.add(2, 'days')
        }
        if ([0, 6].includes(oneMonth.day())) {
          oneMonth.day() == 0 ? oneMonth.add(1, 'days') : oneMonth.add(2, 'days')
        }
        if ([0, 6].includes(twoMonth.day())) {
          twoMonth.day() == 0 ? twoMonth.add(1, 'days') : twoMonth.add(2, 'days')
        }
        if ([0, 6].includes(threeMonth.day())) {
          threeMonth.day() == 0 ? threeMonth.add(1, 'days') : threeMonth.add(2, 'days')
        }

        return [
          {
            display: capitalize(i18n.t('today')),
            value: now
          },
          {
            display: capitalize(i18n.t('tomorrow')),
            value: tomorrow
          },
          {
            display: (capitalize(i18n.tc('inXweeks', 1, { number: 1 })) + ' (' + oneWeek.format('LL') + ')'),
            value: oneWeek
          },
          {
            display: capitalize(i18n.tc('inXweeks', 2, { number: 2 })) + ' (' + twoWeek.format('LL') + ')',
            value: twoWeek
          },
          {
            display: capitalize(i18n.tc('inXmonths', 1, { number: 1 })) + ' (' + oneMonth.format('LL') + ')',
            value: oneMonth
          },
          {
            display: capitalize(i18n.tc('inXmonths', 2, { number: 2 })) + ' (' + twoMonth.format('LL') + ')',
            value: twoMonth
          },
          {
            display: capitalize(i18n.tc('inXmonths', 3, { number: 3 })) + ' (' + threeMonth.format('LL') + ')',
            value: threeMonth
          },
          {
            display: capitalize(i18n.tc('personalizedDate')),
            value: 'personalizedDate'
          },
        ]
      } else {
        return []
      }
    })

    const isEditable = computed(() => {
      if ('isValidated' in props.event && props.event.isValidated !== null) {
        // if (props.event.isValidated == true || props.viewOnly) {
        return false
      } else {
        return true
      }
    })

    const isDisabled = computed(() => {
      return !isEditable.value
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isStartDateMagnet, val => {
      console.log(val)
      setTimeByLastEvent(val)
    })

    watch(lastEventBefore, val => {
      // console.log(val)
      setTimeByLastEvent(isStartDateMagnet.value)
    }, { deep: true })

    watch(startTimeLocal, val => {
      eventLocal.value.startDate = moment(eventLocal.value.startDate).hour(val.split(':')[0]).minute(val.split(':')[1])

      if (isInitialChange.value) {
        isInitialChange.value = false
      } else {
        endTimeLocal.value = moment(startDateLocal.value + ' ' + val).add(slotTimeDuration.value, 'm').format('HH:mm')
      }

    }, { deep: true })
    watch(endTimeLocal, val => {
      eventLocal.value.endDate = moment(eventLocal.value.endDate).hour(val.split(':')[0]).minute(val.split(':')[1])
    }, { deep: true })

    watch(selectedProjectLocal, val => {
      if (val == null) {
        // console.log('L1698: null')
        eventLocal.value.workFlo = null
        eventLocal.value.workflow = null
        eventLocal.value.phase = null

        displayProjectPropositions.value = true
      } else {
        // console.log(val)
        displayProjectPropositions.value = false

        let concernedProjectOption = projectOptions.value.find(x => x.i == val)

        if (concernedProjectOption.category == 'workFlo') {
          eventLocal.value.workFlo = concernedProjectOption.workFloId
          eventLocal.value.workflow = null
          eventLocal.value.phase = null
        } else if (concernedProjectOption.category == 'workflow') {
          eventLocal.value.workFlo = null

          if (eventLocal.value.workflow == null || concernedProjectOption.workflowId != eventLocal.value.workflow.id) {
            eventLocal.value.workflow = concernedProjectOption.workflowId
            eventLocal.value.phase = null
          }

          updatePhases(concernedProjectOption.workflowId)
        }
      }
    })

    watch(selectedMinute, val => {
      // console.log(val)
      let hours = val.split(':')[0]
      let minutes = val.split(':')[1]
      // eventLocal.value.endDate = moment(clone(eventLocal.value.startDate)).add(val, 'minutes')
      eventLocal.value.endDate = moment(clone(eventLocal.value.startDate)).add(hours, 'hours').add(minutes, 'minutes')

      if (['call', 'discussion', 'mail'].includes(eventLocal.value.type)) {
        eventLocal.value._duration = val
      } else {
        delete eventLocal.value._duration
      }
      // console.log(clone(eventLocal.value._duration))
    })

    watch(absenceDates, val => {
      if (val != null) {
        if (Array.isArray(val)) {
          let startDate = moment(val[0])
          let endDate = moment(val[1])

          let startDay = moment(startDate).format('D')
          let startMonth = moment(startDate).format('M') - 1
          let startYear = moment(startDate).format('Y')

          let endDay = moment(endDate).format('D')
          let endMonth = moment(endDate).format('M') - 1
          let endYear = moment(endDate).format('Y')

          eventLocal.value.startDate = moment(eventLocal.value.startDate).year(startYear).month(startMonth).date(startDay)
          eventLocal.value.endDate = moment(eventLocal.value.endDate).year(endYear).month(endMonth).date(endDay)
        } else {
          if (val.includes('au')) {
            let startDate = moment(val.split(' au ')[0])
            let endDate = moment(val.split(' au ')[1])

            let startDay = moment(startDate).format('D')
            let startMonth = moment(startDate).format('M') - 1
            let startYear = moment(startDate).format('Y')

            let endDay = moment(endDate).format('D')
            let endMonth = moment(endDate).format('M') - 1
            let endYear = moment(endDate).format('Y')

            eventLocal.value.startDate = moment(eventLocal.value.startDate).year(startYear).month(startMonth).date(startDay)
            eventLocal.value.endDate = moment(eventLocal.value.endDate).year(endYear).month(endMonth).date(endDay)
          } else {
            let day = moment(val).format('D')
            let month = moment(val).format('M') - 1
            let year = moment(val).format('Y')

            eventLocal.value.startDate = moment(eventLocal.value.startDate).year(year).month(month).date(day)
            eventLocal.value.endDate = moment(eventLocal.value.endDate).year(year).month(month).date(day)

          }
        }

      }
    })

    watch(startMorningAfternoon, val => {
      // console.log(val)
      if (val == 'morning') {
        eventLocal.value.startDate = moment(eventLocal.value.startDate).hour(8).minute(0)
      } else {
        eventLocal.value.startDate = moment(eventLocal.value.startDate).hour(14).minute(0)
      }
    })

    watch(endMorningAfternoon, val => {
      // console.log(val)
      if (val == 'morning') {
        eventLocal.value.endDate = moment(eventLocal.value.endDate).hour(12).minute(0)
      } else {
        eventLocal.value.endDate = moment(eventLocal.value.endDate).hour(18).minute(0)
      }
    })

    watch(closeReminderLocal, val => {
      eventLocal.value.closeReminder = val
    })

    watch(startDateLocal, val => {
      // console.log(clone(val))

      // if (['call', 'discussion'].includes(eventLocal.value.type)) {
      if (
          !['absence'].includes(eventLocal.value.type) &&
          !['holiday'].includes(eventLocal.value.subType)
      ) {
        let startDate = moment(val).format('YYYY-MM-DD')
        let startTime = moment(eventLocal.value.startDate).format('HH:mm:ss')
        let endTime = moment(eventLocal.value.endDate).format('HH:mm:ss')

        eventLocal.value.startDate = moment(startDate + ' ' + startTime).toJSON()
        eventLocal.value.endDate = moment(startDate + ' ' + endTime).toJSON()
      }

    })

    watch(addReminder, val => {
      if (val == true) {
        eventLocal.value._reminderType = 'call'
        // console.log(clone(followUpPeriods.value))

        if (followUpPeriods.value.length > 0) {
          eventLocal.value._reminderPeriodDisplay = followUpPeriods.value[0].display
          eventLocal.value._reminderPeriod = followUpPeriods.value[0].value

          // let span = document.getElementById("popover-reactive-reminder-period").children[0]
          // span.innerHTML = followUpPeriods.value[0].display
        }

      } else {
        eventLocal.value._reminderType = null
        eventLocal.value._reminderPeriodDisplay = null
        eventLocal.value._reminderPeriod = null

        // let span = document.getElementById("popover-reactive-reminder-period").children[0]
        // span.innerHTML = null
      }
    })

    // watch(() => props.event, val => {
    //   console.log(val)
    // }, {deep:true})

    // watch(()=>projectOptions.value, val => {
    //   console.log(val)
    //   if (props.event.workflow != null) {
    //     selectedProjectLocal.value = val.find(p=>p.workflowId == props.event.workflow.id)
    //   }
    // }, {deep:true})

    // watch(eventLocal, val => {
    //   console.log(clone(val))
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { fetchAutocompletePhases } = useAPI()
    const setTimeByLastEvent = bool => {
      // console.log(clone(initialStartDate.value))
      // console.log(clone(initialEndDate.value))

      // console.log(bool)
      // console.log(lastEventBefore.value)
      if (bool == true) {
        if (lastEventBefore.value == null) {
          startTimeLocal.value = moment(clone(initialStartDate.value)).format('HH:mm')
          // console.log(startTimeLocal.value)
        } else {
          startTimeLocal.value = moment(lastEventBefore.value.endDate).format('HH:mm')
          // console.log(startTimeLocal.value)
        }

      } else {
        isInitialChange.value = true

        startTimeLocal.value = moment(clone(initialStartDate.value)).format('HH:mm')
        endTimeLocal.value = moment(clone(initialEndDate.value)).format('HH:mm')
        // console.log(startTimeLocal.value)
      }
    }

    const getUserLastAnnexations = additionalMembers => {
      projectPropositions.value = []

      if (additionalMembers.length) {
        store.dispatch('user2/getUserLastAnnexations', additionalMembers[0])
            .then(response => {
              let wf = 0
              response.data.workFlos.forEach(w => {
                if (wf < 3) {
                  projectPropositions.value.push({
                    i: projectOptions.value.find(o => o.category == 'workFlo' && o.workFloId == w.id).i,
                    category: 'workFlo',
                    workFloId: w.id,
                    display: w._display
                  })
                }
                wf++
              })

              let wfw = 0
              response.data.workflows.forEach(wf => {
                if (wfw < 1) {
                  projectPropositions.value.push({
                    i: projectOptions.value.find(o => o.category == 'workflow' && o.workflowId == wf.id).i,
                    category: 'workflow',
                    workflowId: wf.id,
                    display: wf._display
                  })
                }
                wfw++
              })

            })
      }

    }

    const getUserLastPlaces = additionalMembers => {
      addressPropositions.value = []

      if (additionalMembers.length) {
        store.dispatch('user2/getUserLastPlaces', additionalMembers[0])
            .then(response => {
              for (let i = 0; i < 3; i++) {
                if (response.data[i] !== undefined) {
                  addressPropositions.value.push(response.data[i])
                }
              }
              // addressPropositions.value = response.data
            })
      }
    }

    const getUserLastTrips = additionalMembers => {
      addressTripPropositions.value = []

      if (additionalMembers.length) {
        store.dispatch('user2/getUserLastTrips', additionalMembers[0])
            .then(response => {
              for (let i = 0; i < 3; i++) {
                if (response.data[i] !== undefined) {
                  addressTripPropositions.value.push(response.data[i])
                }
              }
            })
      }
    }

    const additionalMembersUpdated = additionalMembers => {
      // console.log(additionalMembers)

      if (eventLocal.value.workFlo == null && eventLocal.value.workflow == null) {
        selectedProjectLocal.value = null
      }

      getUserLastAnnexations(additionalMembers)
      getUserLastPlaces(additionalMembers)
      getUserLastTrips(additionalMembers)
    }

    const updatePhases = (worflowId) => {
      if (worflowId != null) {
        fetchAutocompletePhases({
          filters: [{
            sectionLogicOperator: 'AND',
            sectionFilters: [
              {
                logicOperator: 'AND',
                operator: 'equal',
                field: 'workflow',
                value: worflowId
              }
            ]
          }]
        })
      }
    }

    const intervertAddresses = () => {
      if (
          eventLocal.value.startPlace != null &&
          eventLocal.value.startPlace != '' &&
          eventLocal.value.endPlace != null &&
          eventLocal.value.endPlace != ''
      ) {
        let startPlace = clone(eventLocal.value.startPlace)
        let endPlace = clone(eventLocal.value.endPlace)
        eventLocal.value.startPlace = endPlace
        eventLocal.value.endPlace = startPlace
      }
    }

    const selectTripAddresses = addressProposition => {
      eventLocal.value.startPlace = addressProposition.startPlace._display
      eventLocal.value.endPlace = addressProposition.endPlace._display
    }

    const eventTypeChange = (newType) => {
      // console.log('start', moment(clone(eventLocal.value.startDate)).format('LLL'))
      // console.log('end', moment(clone(eventLocal.value.endDate)).format('LLL'))

      eventLocal.value.type = newType
      eventLocal.value.visioLink = null
      eventLocal.value.discussionPlatform = null
      eventLocal.value.place = null
      eventLocal.value.callResultType = null
      eventLocal.value.subType = null
      delete eventLocal.value._duration

      if (newType == 'call') {
        // selectedMinute.value = 15
        selectedMinute.value = '00:15'
        eventLocal.value.callResultType = 'successful call'
      } else if (newType == 'discussion') {
        // selectedMinute.value = 15
        selectedMinute.value = '00:15'

        eventLocal.value.discussionPlatform = 'Présentiel'
      } else if (newType == 'mail') {
        selectedMinute.value = '00:15'
      } else if (newType == 'absence') {
        // absenceDates.value = moment(clone(eventLocal.value.startDate)).format('YYYY-MM-DD') + ' au ' + moment(clone(eventLocal.value.endDate)).format('YYYY-MM-DD')
        // absenceDates.value = moment(clone(eventLocal.value.startDate)).format('YYYY-MM-DD')
        // console.log( eventLocal.value.startDate)
        // console.log(clone(eventLocal.value))
        startMorningAfternoon.value = 'morning'
        endMorningAfternoon.value = 'afternoon'
        eventLocal.value.startDate = moment(eventLocal.value.startDate).hour(8).minute(0)
        eventLocal.value.endDate = moment(eventLocal.value.endDate).hour(18).minute(0)

      } else if (newType == 'holiday') {
        eventLocal.value.subType = 'holiday'
        startMorningAfternoon.value = 'morning'
        endMorningAfternoon.value = 'afternoon'
        eventLocal.value.startDate = moment(eventLocal.value.startDate).hour(8).minute(0)
        eventLocal.value.endDate = moment(eventLocal.value.endDate).hour(18).minute(0)

      } else if (newType == 'discussion') {
        // selectedMinute.value = 15
        selectedMinute.value = '00:15'

        eventLocal.value.discussionPlatform = 'Présentiel'
      } else if (newType == 'trip') {
        // console.log(getUserData())
        // console.log(getUserData().companyMainAddress)
        if (getUserData().companyMainAddress != null) {
          // eventLocal.value.startPlace = getUserData().companyMainAddress

          setTimeout(() => {
            eventLocal.value.startPlace = getUserData().companyMainAddress
            // startPlaceRef.value.updateField({
            //   _display: getUserData().companyMainAddress
            // })
          }, 100)
        }
        eventLocal.value.discussionPlatform = 'SMS'
      } else {
        // selectedMinute.value = clone(slotTimeDuration.value)
        selectedMinute.value = '00:' + clone(slotTimeDuration.value)
      }

      // console.log(eventLocal.value.startDate)
      // console.log(eventLocal.value.endDate)
    }

    const callResultClick = newResultType => {
      eventLocal.value.callResultType = newResultType.value

      if (['wrong number', 'busy', 'no response', 'voice message'].includes(newResultType.value)) {
        // selectedMinute.value = 5
        selectedMinute.value = '00:05'
      } else {
        // selectedMinute.value = 15
        selectedMinute.value = '00:15'
      }
    }

    const changeReminderPeriod = selectedReminderPeriod => {
      // console.log(selectedReminderPeriod)
      eventLocal.value._reminderPeriodDisplay = selectedReminderPeriod.display

      if (selectedReminderPeriod.value == 'personalizedDate') {
        eventLocal.value._reminderPeriod = moment().toJSON()
        displayReminderPeriodCalendar.value = true
      } else {
        displayReminderPeriodCalendar.value = false
        eventLocal.value._reminderPeriod = selectedReminderPeriod.value
      }

      // eventLocal.value._reminderPeriodDisplay = selectedReminderPeriod.display
      // eventLocal.value._reminderPeriod = selectedReminderPeriod.value
      componentKey.value++

      // let span = document.getElementById("popover-reactive-reminder-period").children[0]
      // span.innerHTML = selectedReminderPeriod.display
    }

    const selectTemplate = template => {
      store.dispatch('template/getTemplate', template.id)
          .then(response => {
            for (const [key, value] of Object.entries(response.data.template)) {
              eventLocal.value[key] = value
            }

            if (
                ['call', 'discussion', 'mail'].includes(response.data.template.type) &&
                '_duration' in response.data.template
            ) {
              selectedMinute.value = response.data.template._duration
            }

            let additionalMembers = []
            if (response.data.template.additionalMembers.length != 0) {
              response.data.template.additionalMembers.forEach(additionalMember => {
                additionalMembers.push(autocompleteUsers.value.find(u => u.id == additionalMember))
              })

              eventLocal.value.additionalMembers = additionalMembers
            }

            if (response.data.template.workflow != null) {
              selectedProjectLocal.value = projectOptions.value.find(p => {
                return p.workflowId != null && p.workflowId == response.data.template.workflow
              }).i

              let phaseId = clone(response.data.template.phase)
              setTimeout(() => {
                eventLocal.value.phase = autocompletePhases.value.find(p => p.id == phaseId)
              }, 500)
            }

            usersInput.value.update(additionalMembers)
          })
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    setTimeByLastEvent(isStartDateMagnet.value)

    if (props.event.reminder != null && 'isClosed' in props.event.reminder) {
      closeReminderLocal.value = props.event.reminder.isClosed
    }

    if (props.event.workflow != null) {
      selectedProjectLocal.value = projectOptions.value.find(p => {
        return p.workflowId != null && p.workflowId == (isObject(props.event.workflow) ? props.event.workflow.id : props.event.workflow)
      }).i
    }
    if (props.event.workFlo != null) {
      selectedProjectLocal.value = projectOptions.value.find(p => p.workFloId != null && p.workFloId == props.event.workFlo.id).i
    }
    if (props.event.additionalMembers.length) {
      let additionalMembersIds = []
      props.event.additionalMembers.forEach(m => additionalMembersIds.push(m.id))

      getUserLastAnnexations(additionalMembersIds)
    }

    if (['call', 'discussion', 'mail'].includes(props.event.type)) {
      // selectedMinute.value = minuteOptions.find(m => m.value == moment(props.event.endDate).diff(props.event.startDate, 'minutes'))
      let duration = moment.duration(moment(props.event.endDate).diff(props.event.startDate))
      selectedMinute.value = duration.hours() + ':' + duration.minutes()
    }

    if (props.event.type == 'absence' || props.event.subType == 'holiday') {
      // if (moment(props.event.startDate).format('YYYY-MM-DD') == moment(props.event.endDate).format('YYYY-MM-DD')) {
      //   absenceDates.value = moment(props.event.startDate).format('YYYY-MM-DD')
      // } else {
      //   absenceDates.value = moment(props.event.startDate).format('YYYY-MM-DD') + ' au ' + moment(props.event.endDate).format('YYYY-MM-DD')
      // }
      absenceDates.value = [moment(props.event.startDate).format('YYYY-MM-DD'), moment(props.event.endDate).format('YYYY-MM-DD')]

      if (props.event.subType == 'holiday') {
        eventLocal.value.type == 'holiday'
      }

      if (moment(props.event.startDate).format('k') == 8) {
        startMorningAfternoon.value = 'morning'
      } else {
        startMorningAfternoon.value = 'afternoon'
      }

      if (moment(props.event.endDate).format('k') == 18) {
        endMorningAfternoon.value = 'afternoon'
      } else {
        endMorningAfternoon.value = 'morning'
      }
    }

    if (
        props.isTemplate &&
        ['call', 'discussion', 'mail'].includes(props.event.type) &&
        '_duration' in props.event
    ) {
      selectedMinute.value = props.event._duration
    }

    if (props.isTemplate) {
      // console.log('is Template')
      let additionalMembers = []
      if (props.event.additionalMembers.length != 0) {
        props.event.additionalMembers.forEach(additionalMember => {
          additionalMembers.push(autocompleteUsers.value.find(u => u.id == additionalMember))
        })

        eventLocal.value.additionalMembers = additionalMembers
      }

      if (props.event.workflow != null) {
        updatePhases(props.event.workflow)

        let phaseId = clone(eventLocal.value.phase)
        setTimeout(() => {
          eventLocal.value.phase = autocompletePhases.value.find(p => p.id == phaseId)
        }, 500)
      }
    }


    // let x = []
    // for (let i = 1; i < 60; i++) {
    //   x.push({
    //     display: i + " " + i18n.t("min"),
    //     value: i
    //   })
    // }
    // console.log(x)

    return {
      // Components
      capitalize,
      moment,

      // Data
      eventIcons,
      startDateLocal,
      startTimeLocal,
      startTimeZoneLocal,
      endDateLocal,
      endTimeLocal,
      endTimeZoneLocal,
      timeOptions,
      timeZoneOptions,
      isTimeZoneFieldsDisplayed,
      isStartDateMagnet,
      selectedProjectLocal,
      projectPropositions,
      addressPropositions,
      addressTripPropositions,
      minuteOptions,
      selectedMinute,
      discussionOptions,
      startPlaceRef,
      endPlace,
      absenceDates,
      startMorningAfternoonOptions,
      endMorningAfternoonOptions,
      startMorningAfternoon,
      endMorningAfternoon,
      listOfCallResultTypes,
      displayProjectPropositions,
      isDeleteIconHovered,
      closeReminderLocal,
      addReminder,
      listOfReminderTypes,
      displayReminderPeriodCalendar,
      componentKey,
      usersInput,

      // Computed
      eventLocal,
      currentEventIcon,
      autocompleteUsers,
      timeEndOptions,
      projectOptions,
      autocompletePhases,
      isDeletable,
      followUpPeriods,
      isEditable,
      isDisabled,

      // Methods
      setTimeByLastEvent,
      getUserLastAnnexations,
      additionalMembersUpdated,
      eventTypeChange,
      intervertAddresses,
      selectTripAddresses,
      callResultClick,
      changeReminderPeriod,
      selectTemplate,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: { isObject, goToNewTab },
  mounted () {
  },
  created () {
  }
}
</script>

<style
    lang="scss"
>

@import '@/assets/scss/vue/libs/vue-flatpicker.scss';


.form-group {
  margin-bottom: 0
}

.flatpickr-input {
  display: none;
}
</style>
