<template>
  <field
      :name="name"
      :placeholder="placeholder?placeholder:capitalize($tc(name))"
      :model.sync="modelLocal"
      :rules="rules"
      :disabled="disabled"
      @blur="handleBlur"
  />
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'
import { isObject } from '@/utils/utils'

import Field from '@/components/input/Input'
import store from '@/store'

export default {
  components: {
    Field
  },
  props: {
    model: {
      required: true
    },
    name: {
      type: String,
      default: ''
    },
    placeholder: {
      type: String,
    },
    rules: {
      type: String,
      default: ''
    },
    disabled: {
      type: Boolean,
      default: false
    },
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const modelLocal = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const getAddressByGPS = (addressString) => {
      return new Promise((resolve, reject) => {
        store.dispatch('address/getAddressByGPS', addressString)
            .then(response => {
              resolve(response)
            })
            .catch(error => {
              console.log(error)
              reject(error)
            })
      })
    }

    const handleBlur = () => {
      getAddressByGPS(modelLocal.value)
          .then(response => {
            emit('update:model', response.data)
            // props.model = response.data
          })

    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,
      isObject,

      // Data
      modelLocal,

      // Computed

      // Methods
      handleBlur,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
  },
  methods: {},
  mounted () {
    if (this.isObject(this.model) && '_display' in this.model) {
      this.modelLocal = this.model._display
    } else {
      this.modelLocal = this.model
    }
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>