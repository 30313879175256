<template>
  <div>
    <ckeditor
        :editor="editor"
        v-model="textLocal"
        :config="editorConfig"
    />
  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import CKEditor                 from '@ckeditor/ckeditor5-vue2'
import Editor                   from 'ckeditor5-custom-build/build/ckeditor'

export default {
  components: {
    ckeditor: CKEditor.component
  },
  props: {
    text: {
      type: String,
      default: ''
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const editorConfig = ref({
      removePlugins: ['Title'],
      // toolbar: { items: ['restrictedEditing'] },
      toolbar: {
        items: [
          'accessibilityHelp',
          'undo',
          'redo',
          '|',
          'heading',
          // 'style',
          '|',
          'bold',
          'italic',
          'underline',
          'strikethrough',
          'subscript',
          'superscript',
          'link',
          'bulletedList',
          'numberedList',
          'todoList',
          '|',
          'alignment',
          'outdent',
          'indent',
          '|',
          'imageInsert',
          // 'imageUpload',
          'blockQuote',
          'insertTable',
          'mediaEmbed',
          // 'code',
          // 'codeBlock',
          'findAndReplace',
          '|',
          'fontColor',
          'fontBackgroundColor',
          'fontFamily',
          'fontSize',
          'highlight',
          '|',
          // 'htmlEmbed',
          // 'pageBreak',
          // 'removeFormat',
          // 'selectAll',
          'horizontalLine',
          'showBlocks',
          '|',
          'specialCharacters',
          // '|',
          // 'restrictedEditingException',
          'sourceEditing',
          // 'textPartLanguage',
        ]
      },
      language: 'fr',
      image: {
        toolbar: [
          'imageTextAlternative',
          'toggleImageCaption',
          'imageStyle:inline',
          'imageStyle:block',
          'imageStyle:side',
          'linkImage'
        ]
      },
      table: {
        contentToolbar: [
          'tableColumn',
          'tableRow',
          'mergeTableCells',
          'tableCellProperties',
          'tableProperties'
        ]
      }
    })
    const editor = ref(Editor.Editor)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const textLocal = computed({
      get () {
        return props.text
      },
      set (val) {
        emit('update:text', val)
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      editorConfig,
      editor,

      // Computed
      textLocal,

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>