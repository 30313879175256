<template>
  <b-card
      :no-body="!withToolbar"
  >

    <b-card-text v-if="withToolbar">
      <calendar-toolbar
          @addEvent="$emit('addEvent')"
      />

    </b-card-text>


    <div class="app-calendar overflow-hidden border">
      <div class="row no-gutters">

        <!-- Sidebar -->
        <div
            v-if="withSidebar"
            class="col app-calendar-sidebar flex-grow-0 overflow-hidden d-flex flex-column"
            :class="{'show': isCalendarOverlaySidebarActive}"
        >
          <calendar-sidebar
              :is-event-handler-sidebar-active.sync="isEventHandlerSidebarActive"
              :calendars="calendars"
          />
        </div>

        <!-- Calendar -->
        <div class="col position-relative">
          <div class="card shadow-none border-0 mb-0 rounded-0">
            <div class="card-body pb-0">

              <full-calendar
                  ref="refCalendar"
                  :options="calendarOptions"
                  class="full-calendar"
                  @dateClick="$emit('dateClick', $event)"
                  @updateEventDates="$emit('updateEventDates', $event)"
                  @duplicateEvent="$emit('duplicateEvent', $event)"
                  @deleteEvent="$emit('deleteEvent', $event)"
              />

            </div>
          </div>
        </div>
      </div>
    </div>
  </b-card>
</template>

<script>
import FullCalendar from '@fullcalendar/vue'

import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { useCalendar } from './useCalendar'

import CalendarToolbar from './components/_toolbar'
import CalendarSidebar from './components/_sidebar'

export default {
  components: {
    FullCalendar,
    CalendarToolbar,
    CalendarSidebar,
  },
  props: {
    // events: {
    //   type: Array,
    //   default: () => []
    // },
    // calendars: {
    //   type: Array,
    //   default: () => []
    // },
    withSidebar: {
      type: Boolean,
      default: false
    },
    withToolbar: {
      type: Boolean,
      default: false
    }
  },
  setup (props, {emit}) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const {
      refCalendar,
      viewDates,
      event,
      calendarOptions,
      isEventHandlerSidebarActive,
      isCalendarOverlaySidebarActive,
      addEvent,
      updateEvent,
      refetchEvents,
      refetchEventAfterUpdate,
      removeCalendarEvent,
    } = useCalendar()

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(viewDates, val => {
      emit('newCalendarDates', val)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      refCalendar,
      calendarOptions,
      isEventHandlerSidebarActive,
      isCalendarOverlaySidebarActive,

      // Computed

      // Methods
      refetchEvents,
      refetchEventAfterUpdate,
      removeCalendarEvent,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">
@import '@/assets/scss/base/pages/app-calendar.scss';
</style>