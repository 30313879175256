<template>
  <b-row>
    <b-col md="12" class="d-flex justify-content-between">
      <!--          Start place-->
      <field-address-by-query
          :name="'startPlace'"
          :model.sync="event.startPlace"
          :isRequired="true"
          :displayComplement="false"
          :disabled="disabled"
          ref="startPlace"
          class="w-100"
      />

      <icon
          icon="exchange-alt"
          class="mt-2 mx-2 cursor-pointer"
          style="padding-top: 10px"
          @click.native="intervertAddresses"
      />

      <!--          End place-->
      <field-address-by-query
          :name="'endPlace'"
          :model.sync="event.endPlace"
          :isRequired="true"
          :displayComplement="false"
          :disabled="disabled"
          ref="endPlace"
          class="w-100"
      />
    </b-col>

<!--    &lt;!&ndash;          Start place&ndash;&gt;-->
<!--    <b-col md="6">-->
<!--      <field-address-by-query-->
<!--          :name="'startPlace'"-->
<!--          :model.sync="event.startPlace"-->
<!--          :isRequired="true"-->
<!--          :displayComplement="false"-->
<!--          :disabled="disabled"-->
<!--      />-->
<!--    </b-col>-->

<!--    &lt;!&ndash;          End place&ndash;&gt;-->
<!--    <b-col md="6">-->
<!--      <field-address-by-query-->
<!--          :name="'endPlace'"-->
<!--          :model.sync="event.endPlace"-->
<!--          :isRequired="true"-->
<!--          :displayComplement="false"-->
<!--          :disabled="disabled"-->
<!--      />-->
<!--    </b-col>-->

    <!--          Trip with personal vehicle-->
    <b-col cols="12">
      <span>{{ capitalize($t('tripWithPersonalVehicle')) }}</span>
      <b-form-checkbox
          v-model="event.isTripWithPersonalVehicle"
          class="custom-control-primary form-group"
          switch
          :disabled="disabled"
      >
      </b-form-checkbox>
    </b-col>

  </b-row>
</template>

<script>
import { ref, computed, watch, onMounted } from '@vue/composition-api'
import { capitalize } from '@/utils/filter'

import FieldAddress from '@/components/input/Address'
import FieldAddressByQuery from '../../../input/AddressAutosuggest'

export default {
  components: {
    // FieldAddress,
    FieldAddressByQuery
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    disabled: {
      type: Boolean,
      default: true
    }
  },
  setup (props) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const startPlace = ref(null)
    const endPlace = ref(null)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const intervertAddresses = () => {
      startPlace.value.update(JSON.parse(JSON.stringify(props.event.endPlace)))
      endPlace.value.update(JSON.parse(JSON.stringify(props.event.startPlace)))
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data
      startPlace,
      endPlace,

      // Computed

      // Methods
      intervertAddresses
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {  },
  methods: {},
  mounted () {
    // console.log(this.event)
    // console.log(this.event.startPlace)
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>