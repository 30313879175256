<template>
    <b-row>
        <b-col md="8">
            <calendar
                    ref="calendar"
                    @newCalendarDates="calendarDatesChange"

            />
        </b-col>
        <b-col md="4">
            <tasks
                    :tasks="tasks"
                    @updateCalendar="updateCalendar"/>
        </b-col>
    </b-row>

</template>

<script>
import { computed, ref, watch, onMounted } from '@vue/composition-api'
import { getUserData } from '../../utils/utils'
import store from '@/store'
import i18n from '@/libs/i18n'

import Calendar from '../../components/home/calendar/Calendar'
import Tasks from '../../components/tasks/Tasks'
import useAPI from '../../utils/useAPI'

export default {
  components: {
    Tasks,
    Calendar,
  },
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const calendar = ref(null)
    const firstLoad = ref(true)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const {
      tasks,
      autocompleteUsers
    } = useAPI()

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const {
      fetchAutocompleteWorkflows,
      fetchAutocompleteUsers,
        fetchAutocompleteWorkFlos,
      fetchAutocompleteEmployees,

      fetchCalendars,
      fetchTasks,
    } = useAPI()

    const updateCalendar = () => {
      calendar.value.$refs.calendar.refetchEvents()
    }

    const calendarDatesChange = (dates) => {
      fetchCalendars({ dates: dates }).then(() => {
        firstLoad.value = false
      })

      if (firstLoad.value) {
        fetchCalendars({ dates: dates })
      }
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    fetchTasks()

    // fetchAutocompleteEmployees()
    fetchAutocompleteUsers({
      filters: [
        {
          sectionLogicOperator: "AND",
          sectionFilters: [
            {
              logicOperator: "AND",
              field: "invitationCode",
              operator: "is_not_empty",
              value: null
            },
            {
              logicOperator: "AND",
              field: "password",
              operator: "is_not_empty",
              value: null
            },
          ]
        }
      ]
    }).then(() => {
      autocompleteUsers.value.forEach(e => {
        if (!store.getters['calendar/getCalendars'].some(c => c.name == e._display)) {
          store.commit('calendar/ADD_CALENDAR', {
            name: e._display,
          })
        }

        store.commit('calendar/SET_SELECTED_CALENDARS', [getUserData()._display])
      })

    })
    fetchAutocompleteWorkFlos()
    fetchAutocompleteWorkflows()

    return {
      // Components

      // Data
      calendar,

      // Computed
      tasks,
      // workflows,
      // x,

      // Methods
      updateCalendar,
      calendarDatesChange,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>