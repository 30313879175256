<template>
  <b-tabs>


    <!-- WorkFlo -->
    <b-tab :title="capitalize($t('opportunity'))">
      <b-card-text>
        <tab-work-flo
            :taskEvent="taskEvent"
            :isDisabled="isContentDisabled"
        />
      </b-card-text>
    </b-tab>

    <!-- Workflow & phase -->
    <b-tab :title="capitalize($t('workflow'))" class="w-100">
      <b-card-text>
        <tab-workflow
            :taskEvent="taskEvent"
            :isDisabled="isContentDisabled"
        />
      </b-card-text>
    </b-tab>

    <!-- To do list -->
    <b-tab
        :title="capitalize($tc('step',2)) + ' (' + taskEvent.steps.length+')'"
    >
      <b-card-text>
        <tab-to-do-list
            :taskEvent="taskEvent"
            :isDisabled="isContentDisabled"
        />
      </b-card-text>
    </b-tab>

    <!-- Notes -->
    <b-tab :title="capitalize($t('notes'))">
      <b-card-text>
        <tab-notes
            :taskEvent="taskEvent"
            :isDisabled="isContentDisabled"
        />
      </b-card-text>
    </b-tab>

    <!-- Documents -->
    <b-tab :title="capitalize($tc('document',2)) + ' (' + taskEvent.documents.length+')'">
      <b-card-text>
        <tab-documents
            :taskEvent="taskEvent"
            :isDisabled="isContentDisabled"
        />
      </b-card-text>
    </b-tab>


  </b-tabs>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { capitalize } from '../../../utils/filter'

import TabToDoList from './components/_tabToDoList'
import TabWorkflow from './components/_tabWorkflow'
import TabNotes from './components/_tabNotes'
import TabDocuments from './components/_tabDocuments'
import TabWorkFlo from './components/_tabWorkFlo.vue'

export default {
  components: {
    TabToDoList,
    TabWorkflow,
    TabNotes,
    TabDocuments,
    TabWorkFlo
  },
  props: {
    taskEvent: {
      type: Object,
      default: () => {}
    },
    displayToDoList: {
      type: Boolean,
      default: () => {}
    },
    isContentDisabled: {
      type: Boolean,
      default: false
    }
  },
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components
      capitalize,

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
    // console.log(this.taskEvent)
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>