import { render, staticRenderFns } from "./Event2Tabs.vue?vue&type=template&id=7845e1fb&scoped=true&"
import script from "./Event2Tabs.vue?vue&type=script&lang=js&"
export * from "./Event2Tabs.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "7845e1fb",
  null
  
)

export default component.exports