<template>
  <b-modal
      v-model="isOpenLocal"
      size="lg"
      :hide-footer="hideFooter"
      :ok-title="capitalize($t('validate'))"
      :cancel-title="capitalize($t('cancel'))"
      @hide="tryCloseEventModal"
      @hidden="handleCancel"
      @ok="handleOk"
  >
    <template #modal-title>
      <span
          v-b-tooltip.hover.right
          :title="createdBy + ' - ' + createdAt"
      >
        {{ title }}
      </span>
    </template>

    <validation-observer
        ref="observerRef"
        #default="{handleSubmit}"
    >
      <b-form
          @keydown.enter.stop.prevent=""
          @submit.stop.prevent="handleSubmit(submitValidatedEventLocal)"
      >
        <event2-form
            v-bind:event="eventLocal"
            view-only="view-only"
            :is-template="isTemplate"

            @removeEvent="$emit('removeEvent')"
        />
        <button
            ref="submitEventFormRef"
            class="d-none"
        ></button>
      </b-form>
    </validation-observer>


    <!--            <b-card>-->
    <!--              <validation-observer-->
    <!--                  ref="observerRef"-->
    <!--                  #default="{handleSubmit}"-->
    <!--              >-->
    <!--                <b-form-->
    <!--                    @keydown.enter.stop.prevent=""-->
    <!--                    @submit.stop.prevent="handleSubmit(submitValidatedEventLocal)">-->
    <!--                  <form-event :event="eventLocal"-->
    <!--                              @deleteEvent="$emit('deleteEvent')"/>-->
    <!--                  <button ref="submitEventFormRef"-->
    <!--                          class="d-none"></button>-->
    <!--                </b-form>-->
    <!--              </validation-observer>-->
    <!--            </b-card>-->

  </b-modal>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import { ValidationObserver }   from 'vee-validate'
import useAPI                   from '../../utils/useAPI'
import FormEvent                from '../../components/form/event/Event'
import Event2Form               from '../form/event2/Event2Form.vue'
import moment                   from 'moment'
import { clone }                from '../../utils/utils'
import { capitalize }           from '../../utils/filter'

export default {
  components: {
    ValidationObserver,
    FormEvent,
    Event2Form,
  },
  props: {
    event: {
      type: Object,
      default: () => {}
    },
    title: {
      type: String,
      default: ''
    },
    isOpen: {
      type: Boolean,
      default: false
    },
    viewOnly: {
      type: Boolean,
      default: false
    },
    isTemplate: {
      type: Boolean,
      default: false
    }
  },
  setup (props, { emit }) {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isOpenLocal = ref(false)
    const eventLocal = ref(props.event)
    const submitEventFormRef = ref(null)
    const observerRef = ref(null)
    // const eventTmp = ref({
    //   discussionPlatform: null,
    //   endDate: clone(props.event.endDate),
    //   endPlace: null,
    //   isOnlineMeeting: true,
    //   phase: null,
    //   place: null,
    //   startDate: clone(props.event.startDate),
    //   startPlace: null,
    //   steps: [],
    //   type: 'work (default)',
    //   visioLink: null,
    //   workFlo: null,
    //   workflow: null,
    // })

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const { currentBreakPoint } = useAPI()

    const hideFooter = computed(() => {
      return !['xs', 'sm'].includes(currentBreakPoint.value)
    })

    const createdAt = computed(() => {
      if ('_createdAt' in props.event) {
        return moment(props.event._createdAt).format('L')
      } else if ('logs' in props.event && props.event.logs.length > 0) {
        return moment(props.event.logs[0].at).format('L')
      } else {
        return moment().format('L')
      }
    })

    const createdBy = computed(() => {
      if ('_createdBy' in props.event) {
        return props.event._createdBy._display
      } else if ('logs' in props.event && props.event.logs.length > 0) {
        return props.event.logs[0].by._display
      } else {
        return ''
      }
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(isOpenLocal, (val) => {
      emit('update:isOpen', val)
    })
    watch(eventLocal, (val) => {
      // console.log(val)
      emit('update:event', val)
    }, { deep: true })

    // watch(eventTmp, val => {
    //   console.log(val)
    // })

    // watch(eventTmp, val => {
    //   console.log(val)
    // }, { deep: true })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const tryCloseEventModal = (modalEvent) => {
      if (modalEvent.trigger == 'backdrop') {
        // Click outside
        modalEvent.preventDefault()
        submitEventFormRef.value.click()
      }
    }

    const submitValidatedEventLocal = () => {
      emit('submitValidatedEvent')
    }

    const handleOk = async (bvModalEvent) => {
      bvModalEvent.preventDefault()

      const isValid = await observerRef.value.validate()
      if (isValid) {
        submitValidatedEventLocal()
      }
    }

    const handleCancel = () => {}

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data
      isOpenLocal,
      eventLocal,
      submitEventFormRef,
      observerRef,
      // eventTmp,

      // Computed
      hideFooter,
      createdAt,
      createdBy,

      // Methods
      tryCloseEventModal,
      submitValidatedEventLocal,
      handleOk,
      handleCancel,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {
    event: function (val) {
      this.eventLocal = val
    },
    isOpen: function (val) {
      this.isOpenLocal = val
    }
  },
  methods: { capitalize },
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>