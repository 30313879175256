import store from '@/store'
import i18n from '@/libs/i18n'

export const useTasks = () => {

  const submitValidatedTask = (task) => {
    return new Promise((resolve, reject) => {
      if ('id' in task && task.id != null) {
        updateTask(task)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewTask(task)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewTask = (task) => {
    return new Promise((resolve, reject) => {
      store.dispatch('task/addTask', task)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateTask = (task) => {
    return new Promise((resolve, reject) => {
      store.dispatch('task/updateTask', task)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeTask = (task) => {
    return new Promise((resolve, reject) => {
      store.dispatch('task/removeTask', task)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  return {
    submitValidatedTask,
    removeTask,
  }
}