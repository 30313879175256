<template>
  <div>
    <div v-if="allowCollapse && isExpanded==false">
      <div
          class="cursor-pointer rounded px-1 py-50 mb-2"
          :class="isHover?'bg-primary bg-lighten-5':'bg-secondary bg-lighten-6'"

          @mouseover="isHover = true"
          @mouseleave="isHover = false"
          @click="isExpanded=true"
      >
        <div class="d-flex justify-content-between">

          <div class="mr-1">
            <b-avatar
                :text="avatar.text"
                variant="light-primary"
            />
          </div>

          <div class="w-100">
            <div class="d-flex justify-content-between">
              <div class="text-nowrap text-truncate">
                <div class="text-nowrap text-truncate">{{ displayEmailAndName(email.fromRecipient) }}</div>
                <div class="text-nowrap text-truncate">{{ capitalize($t('emailTo:')) }} {{ emailToDisplay }}</div>
              </div>

              <div class="text-right">
                {{ moment(email.receivedDateTime).format('LLL') }}
                <icon
                    v-if="email.hasAttachments"
                    icon="paperclip"
                    class="ml-1"
                />
              </div>
            </div>

            <div>{{ email.bodyPreview }}</div>
          </div>
        </div>
      </div>
    </div>

    <div
        v-if="!allowCollapse || isExpanded"
        :class="{'cursor-pointer mb-2': allowCollapse}"

        @mouseover="isHover = true"
        @mouseleave="isHover = false"
        @click="allowCollapse?isExpanded=false:isExpanded=true"
    >
      <div
          class="bg-white rounded px-1 py-50"
          :class="{'bg-secondary bg-lighten-6': (allowCollapse )}"
      >
        <div class="d-flex justify-content-between">

          <div class="mr-1">
            <b-avatar
                :text="avatar.text"
                variant="light-primary"
            />
          </div>

          <div
              class="w-100"
              style="min-width: 0px"
          >
            <!--              Top-->
            <div class="d-flex justify-content-between">
              <div class="text-nowrap text-truncate">
                <div class="text-nowrap text-truncate">{{ displayEmailAndName(email.fromRecipient.emailAddress) }}</div>
                <div
                    class="text-nowrap text-truncate"
                    v-b-tooltip="{ customClass: 'text-left', variant: 'info' }"
                    :title="emailToDisplay"
                >{{ capitalize($t('emailTo:')) }} {{ emailToDisplay }}
                </div>
                <div
                    v-if="email.ccRecipients.length"
                    class="text-nowrap text-truncate"
                    v-b-tooltip="{ customClass: 'text-left', variant: 'warning' }"
                    :title="emailCcDisplay"
                >{{ $t('Cc:') }} {{ emailCcDisplay }}
                </div>
              </div>
              <div>
                <div
                    class="d-flex justify-content-end"
                >
                  <div
                      class="mr-1 cursor-pointer"
                      :class="{'text-primary': isReplyHover}"

                      @mouseover="isReplyHover = true"
                      @mouseleave="isReplyHover = false"
                      @click="$emit('reply')"
                  >
                    <icon icon="reply"/>
                  </div>
                  <div
                      class="mr-1 cursor-pointer"
                      :class="{'text-primary': isReplyAllHover}"

                      @mouseover="isReplyAllHover = true"
                      @mouseleave="isReplyAllHover = false"
                      @click="$emit('replyAll')"
                  >
                    <icon icon="reply-all"/>
                  </div>
                  <div
                      class="cursor-pointer"
                      :class="{'text-primary': isForwardHover}"

                      @mouseover="isForwardHover = true"
                      @mouseleave="isForwardHover = false"
                      @click="$emit('forward')"
                  >
                    <icon icon="share"/>
                  </div>
                </div>

                <div class="text-right">
                  {{ moment(email.receivedDateTime).format('LLL') }}

                  <icon
                      v-if="allowCollapse && email.hasAttachments"
                      icon="paperclip"
                      class="ml-1"
                  />
                </div>
              </div>
            </div>

            <!--              Attachments-->
            <div
                v-if="email.hasAttachments"
                class="mt-2"
            >
              <b-row>
                <b-col
                    md="3"
                    v-for="(attachment, i) in email.attachments"
                >
                  <document-card
                      v-if="isAllAttachmentsDisplayed!=true?(i < 4):true"
                      :document="attachment"
                      :is-deletable="false"
                      :small="true"

                      @click="downloadAttachment(attachment)"
                      @download="downloadAttachment(attachment)"
                  />
                </b-col>
              </b-row>

              <div class="d-flex">
                <div
                    v-if="email.attachments.length >= 4 && isAllAttachmentsDisplayed == false"
                    class="cursor-pointer"

                    @click="isAllAttachmentsDisplayed = true"
                >
                  <icon
                      icon="angle-double-down"
                      class="mr-50"
                  />
                  <span>
                    {{ capitalize($t('displayNumberAttachments', { number: email.attachments.length })) }}
                  </span>
                </div>

                <div
                    v-if="email.attachments.length >= 2"
                    class="ml-1 cursor-pointer"

                    @click="downloadAllAttachmentsLocal"
                >
                  <icon
                      icon="arrow-down"
                      class="mr-50"
                  />
                  <span>
                    {{ capitalize($t('downloadAll')) }}
                  </span>
                </div>
              </div>


            </div>


            <!--          Email-->
            <div
                class="mt-2 overflow-auto"
                style="max-height: 600px"
            >
              <iframe
                  class="w-100 border-0 position-relative"
                  onload="this.style.height=(this.contentWindow.document.body.scrollHeight+35)+'px';"
                  :srcdoc="('body' in email)?(email.body.content):''"
              ></iframe>
            </div>

            <!--          Buttons-->
            <div
                v-if="!readOnly"
                class="d-flex mt-2 mb-1"
            >
              <b-button
                  variant="outline-primary"
                  size="sm"
                  class="mr-1"

                  @click="$emit('replyAll')"
              >
                <icon
                    icon="reply"
                    class="mr-50"
                />
                {{ capitalize($t('reply')) }}
              </b-button>

              <b-button
                  variant="outline-primary"
                  size="sm"

                  @click="$emit('forward')"
              >
                <icon
                    icon="share"
                    class="mr-50"
                />
                {{ capitalize($t('forward')) }}
              </b-button>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, watch, onMounted }                        from '@vue/composition-api'
import { clone, downloadByDocument, initials, initialsFromEmail } from '../../../../utils/utils'
import { capitalize }                                             from '../../../../utils/filter'
import moment                                                     from 'moment'
import DocumentCard                                               from '../../../card/Document.vue'
import { useEmails }                                              from '../useEmails'

export default {
  components: { DocumentCard },
  props: {
    email: {
      type: Object,
      default: () => {}
    },
    readOnly: {
      type: Boolean,
      default: false
    },
    allowCollapse: {
      type: Boolean,
      default: false
    }
  },
  setup (props) {
    // console.log(props.email)
    // ------------------------------------------------
    // Data
    // ------------------------------------------------
    const isReplyHover = ref(false)
    const isReplyAllHover = ref(false)
    const isForwardHover = ref(false)
    const isHover = ref(false)
    const isExpanded = ref(false)
    const hiddenEmailBodyContent = ref()
    const isAllAttachmentsDisplayed = ref(false)

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------
    const avatar = computed(() => {
      let text = ''

      let emailAddress = props.email.fromRecipient.emailAddress
      if (emailAddress.name != null && emailAddress.name != '') {
        text = initials(emailAddress.name)
      } else {
        text = initialsFromEmail(emailAddress.address)
      }

      return {
        text: text
      }
    })

    const emailToDisplay = computed(() => {
      let output = []
      props.email.toRecipients.forEach(toRecipient => {
        output.push(displayEmailAndName(toRecipient.emailAddress))
      })

      return output.join(', ')
    })

    const emailCcDisplay = computed(() => {
      let output = []
      props.email.ccRecipients.forEach(ccRecipient => {
        output.push(displayEmailAndName(ccRecipient.emailAddress))
      })

      return output.join(', ')
    })

    const emailBccDisplay = computed(() => {
      let output = []
      props.email.BccRecipients.forEach(BccRecipient => {
        output.push(displayEmailAndName(BccRecipient.emailAddress))
      })

      return output.join(', ')
    })

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------
    watch(hiddenEmailBodyContent, val => {
      console.log(val)
      console.log(val.clientHeight)
    })

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------
    const { downloadAttachment, downloadAllAttachments } = useEmails()
    const displayEmailAndName = emailAddressObject => {
      if (emailAddressObject.name != null && emailAddressObject.name != '') {
        return emailAddressObject.name + ' <' + emailAddressObject.address + '>'
      } else {
        return emailAddressObject.address
      }
    }

    // const downloadAttachment = (attachment) => {
    //   if ('url' in attachment && attachment.url != null) {
    //     downloadByDocument(attachment)
    //   }
    // }

    const downloadAllAttachmentsLocal = () => {
      downloadAllAttachments(props.email.id)
    }

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------

    return {
      // Components
      moment,
      capitalize,

      // Data
      isReplyHover,
      isReplyAllHover,
      isForwardHover,
      isHover,
      isExpanded,
      hiddenEmailBodyContent,
      isAllAttachmentsDisplayed,

      // Computed
      avatar,
      emailToDisplay,
      emailCcDisplay,
      emailBccDisplay,

      // Methods
      displayEmailAndName,
      downloadAttachment,
      downloadAllAttachmentsLocal,
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style
    scoped
    lang="scss"
>

</style>