import store from '@/store'

export const useEvents = () => {

  const submitValidatedEvent = (event) => {

    if (event.type == 'default') {
      event.visioLink = null
      event.place = null
      event.startPlace = null
      event.endPlace = null
    } else if (event.type == 'visio') {
      event.place = null
      event.startPlace = null
      event.endPlace = null
    } else if (event.type == 'site') {
      event.visioLink = null
      event.startPlace = null
      event.endPlace = null
    } else if (event.type == 'trip') {
      event.visioLink = null
      event.place = null
    } else if (event.type == 'holiday') {
      event.visioLink = null
      event.place = null
      event.startPlace = null
      event.endPlace = null
    }

    return new Promise((resolve, reject) => {
      if ('id' in event) {
        updateEvent(event)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      } else {
        saveNewEvent(event)
          .then(response => {
            resolve(response)
          })
          .catch(error => {
            reject(error)
          })
      }
    })
  }

  const saveNewEvent = (event) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/addEvent', event)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const updateEvent = (event) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/updateEvent', event)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  const removeEvent = (event) => {
    return new Promise((resolve, reject) => {
      store.dispatch('event/removeEvent', event)
        .then(response => {
          resolve(response)
        })
        .catch(error => {
          console.log(error)
          reject(error)
        })
    })
  }

  // const getEventColor = (event) => {
  //   if (getEventStatus(event) == 'draft') return { variant: 'secondary', icon: 'pencil-ruler' }
  //   else return { variant: 'primary', icon: 'paper-plane' }
  // }

  // const getEventIcon = (event) => {
  //   if (event.type == 'default') {
  //     return 'user-cog'
  //   } else if (event.type == 'call') {
  //     return 'phone'
  //   } else if (event.type == 'visio') {
  //     return 'headset'
  //   } else if (event.type == 'site') {
  //     return 'briefcase'
  //   } else if (event.type == 'trip') {
  //     return 'suitcase-rolling'
  //   } else if (event.type == 'holiday') {
  //     return 'umbrella-beach'
  //   } else {
  //     return null
  //   }
  // }

  return {
    submitValidatedEvent,
    removeEvent,
    // eventToCalendarEvent,
    // getEventColor,
    // getEventIcon
  }
}