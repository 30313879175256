<template>
  <div>
    <b-card>

      <b-row>
        <b-col cols="12">
          <line-task
              :task="newTask"
              :index="0"
              :isNew="true"
              @submitTask="submitValidatedTaskLocal(newTask)"
          />
        </b-col>
      </b-row>
      <b-row>
        <b-col cols="12">
          <line-task
              v-for="(task, index) in unCompletedTasks"
              :task="task"
              :index="index"
              @submitTask="submitValidatedTaskLocal(task)"
              @addTime="addTime(task)"
              @viewMore="viewMore(task)"
              @delete="deleteTaskLocal(task)"
          />
        </b-col>
      </b-row>

      <b-row>
        <b-col cols="12">
          <collapse accordion type="margin">
            <collapse-item :title="capitalize($t('completedTasks'))">
              <line-task
                  v-for="(task, index) in completedTasks"
                  :task="task"
                  :index="index"
                  @submitTask="submitValidatedTaskLocal(task)"
                  @addTime="addTime(task)"
                  @viewMore="viewMore(task)"
                  @delete="deleteTaskLocal(task)"
              />
            </collapse-item>
          </collapse>
        </b-col>
      </b-row>

    </b-card>


    <!-- modal task-->
    <modal-task
        :task.sync="currentTask"
        :title="taskModalTitle"
        :isOpen.sync="taskModalShow"
        @submitValidatedTask="submitValidatedTaskLocal"
    />

    <!-- modal event-->
    <modal-event
        :event.sync="currentEvent"
        :title="eventModalTitle"
        :isOpen.sync="eventModalShow"
        @submitValidatedEvent="submitValidatedEventLocal"
    />

  </div>
</template>

<script>
import { ref, computed, watch } from '@vue/composition-api'
import Collapse from '../collapse/Collapse'
import CollapseItem from '../collapse/CollapseItem'

import mixinTasks from './mixinTasks'
import Days from '../input/Days'

export default {
  components: {
    Days,
    Collapse,
    CollapseItem
  },
  mixins: [mixinTasks],
  props: {},
  setup () {
    // ------------------------------------------------
    // Data
    // ------------------------------------------------

    // ------------------------------------------------
    // Computed
    // ------------------------------------------------

    // ------------------------------------------------
    // Watch
    // ------------------------------------------------

    // ------------------------------------------------
    // Methods
    // ------------------------------------------------

    // ------------------------------------------------
    // Mounted
    // ------------------------------------------------

    // ------------------------------------------------
    // Setup
    // ------------------------------------------------
    return {
      // Components

      // Data

      // Computed

      // Methods
    }
  },
  data () {
    return {}
  },
  computed: {},
  watch: {},
  methods: {},
  mounted () {
  },
  created () {
  }
}
</script>

<style lang="scss">

</style>